import React, { useEffect, useState } from 'react'

// import logo from '../images/logo.png';
import carewindow_blue from '../images/carewindow_blue.png';
import { Link, useLocation } from 'react-router-dom'
// import axios from 'axios';
import { useNavigate } from 'react-router-dom'
//toast
import toast, { Toaster } from 'react-hot-toast';
import { generateRandomString } from './header';

function Reset() {

  const [token, setToken] = useState('')
  const [isNewUser, setIsNewUser] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setToken(params.get('token'))
    setEmail(params.get('email'))
    setIsNewUser(params.get('isNewUser') === 'true')
  }, [location.search])

  const handleSubmit = async (e) => {
    e.preventDefault() // Prevent default form submission
  
    if (!password) {
      alert("Please enter an valid password.")
      return
    }
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`, 
        {
        method: 'POST',
        
        headers: {
          'Content-Type': 'application/json',
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        body: JSON.stringify({ 
          Email: email, 
          NewPassword: password, 
          Token: token,
        }),
        credentials: 'include',
      }
    );
  
      if (response.ok) {
        toast.success("Password has been set!")
        navigate('/')
      } else if (response.status === 400) {
        toast.error("Your password must have at least one non-alphanumeric character and at least one upper case letter!");}
        else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      toast.error('Failed to set password. Please check your network connection or refresh the page.');
    }
  };
  console.log(email,isNewUser)
  return (
    <div className="full_container">
      <Toaster />
      <div className="container">
        <div className="center verticle_center full_height">
          <div className="login_section">
            <div className="logo_login">
              <div className="center">
                {/* <img className="login-logo" src={logo} alt="Logo" /> */}
                <img className="login-carewindow" width="500" src={carewindow_blue} alt="CareWindow" />
              </div>
            </div>


            <div className="login_form">
              
              {isNewUser ? (
                <label style={{ color: 'black' }}>Welcome! Please set your password.</label>
              ) : (
                
                <label style={{ color: 'black' }}>Plese enter your new password</label>
              )}
              <form id="reset-form" method="post" action="/reset" onSubmit={handleSubmit}>
                <fieldset>
                  <div className="field">
                    <label className="label_field">Password</label>
                    <input type="password" id="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>

                  <div className="field margin_0">
                    <label className="label_field hidden">hidden label</label>

                    {isNewUser ? (

                      <button style={{ width: '120px' }} className="btn cur-p btn-primary" type="submit" >
                        Set password
                      </button>
                    ) : (
                      <button style={{ width: '130px' }} className="btn cur-p btn-primary" type="submit" >
                        Reset password
                      </button>
                    )
                    
                    }
                    <Link className="forgot" to="/">Return to Login Page</Link>
                  </div>
                  <div>
                    
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Reset
import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

// import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';


import ReactDOM from 'react-dom';
import "../css/jac.css";
//import logo from "../images/logo.png";
import carewindow from "../images/carewindow.png";

import deviceProfile from "../images/device.png";
//import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import PhoneCallIcon from "@mui/icons-material/PhoneRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import TrashIcon from "@mui/icons-material/DeleteRounded";
import Switch from "@mui/material/Switch";
import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraVideoCall from "./AgoraVideoCall";
import useAccountStatus from "./useAccountStatus";
import { generateRandomString } from './header';

import { XYPlot, XAxis, YAxis, LineSeries, VerticalBarSeries, HorizontalGridLines, DiscreteColorLegend, RadialChart } from 'react-vis';
// import { DeviceContext } from "./DevicesContext";


// CSS
import "../css/bootstrap.min.css";
import "../style.css";
import "../css/responsive.css";
import "../css/color_2.css";
import "../css/bootstrap-select.css";
import "../css/perfect-scrollbar.css";
import "../css/custom.css";
import "../css/jquery.fancybox.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";




import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import w_3 from "../images/user_img/w-3.jpg";
// import w_2 from "../images/user_img/w-2.jpg";
// import w_1 from "../images/user_img/w-1.jpg";
// import m_3 from "../images/user_img/m-3.jpg";
// import m_2 from "../images/user_img/m-2.jpg";
// import m_1 from "../images/user_img/m-1.jpg";
// import yw_3 from "../images/user_img/yw-3.jpg";
// import yw_2 from "../images/user_img/yw-2.jpg";
// import yw_1 from "../images/user_img/yw-1.jpg";
// import ym_1 from "../images/user_img/ym-1.jpg";
// import ym_2 from "../images/user_img/ym-2.jpg";
// import ym_3 from "../images/user_img/ym-3.jpg";
// import ym_4 from "../images/user_img/ym-4.jpg";
// import ym_5 from "../images/user_img/ym-5.jpg";
import dayjs from "dayjs";

// time selector
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { Typography } from "@mui/material";

//locker icon
import lockedIcon from "../images/Locked_icon.png";
import unlockedIcon from "../images/Unlocked_icon.png";
// import lockedNoArrow from "../images/locked-noArrow.png";
// import unlockedNoArrow from "../images/unlocked-noArrow.png";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import { data } from "jquery";

import { KeyboardVoiceRounded, MicOffRounded, VideocamRounded, VideocamOffRounded } from '@mui/icons-material';




function Userboard() {
  const [activeComponent, setActiveComponent] = useState("Manage Devices");
  const [userInfo, setUserInfo] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const isSuperAdmin = userInfo?.role === "superAdmin";
  const isAdmin = userInfo?.role === "admin";
  const isStaff = userInfo?.role === "staff";
  const isManager = userInfo?.role === "manager";
  const { orgGuid } = useParams();
  const [resetFlag, setResetFlag] = useState(false);


  //  const { isAuthenticated, accountDeleted } = useAccountStatus();

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      const parsedUserInfo = JSON.parse(storedUserInfo);
      setUserInfo(parsedUserInfo);
    }
  }, []);

  useEffect(() => {
    if (isStaff || isManager) {
      if (userInfo?.organizationGuid) {
        fetchOrganization(userInfo?.organizationGuid);
      }
    } else if ((isAdmin || isSuperAdmin) && orgGuid) {
      fetchOrganization(orgGuid);
    }
  }, [userInfo, orgGuid, isStaff, isManager, isAdmin, isSuperAdmin]);

  const fetchOrganization = async (guid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations/${guid}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedOrg(data);
      } else {
        console.log("Failed to fetch organization.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
    // 触发重置
    setResetFlag((prev) => !prev);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return <Dashboard
          resetFlag={resetFlag}
          org={selectedOrg}
        />;
      case "Add Devices":
        return (
          <AddDevices
            setActiveComponent={handleSetActiveComponent}
            org={selectedOrg}
            resetFlag={resetFlag}
          />
        );
      case "Manage Devices":
        return (
          <ManageDevices
            settingsFrom="ManageDevices"
            selectedOrg={selectedOrg}
            userInfo={userInfo}
            resetFlag={resetFlag}
          />
        );
      case "Policy Groups":
        return (
          <PolicyGroups
            settingsFrom="PolicyGroups"
            org={selectedOrg}
            resetFlag={resetFlag}

          />
        );
      case "Announcements":
        return <Announcements org={selectedOrg} resetFlag={resetFlag} />;
      case "Event Calendar":
        return <EventCalendar resetFlag={resetFlag} />;
      case "Manage Users":
        return (
          <ManageUsers
            userInfo={userInfo}
            selectedOrg={selectedOrg}
            orgGuid={orgGuid}
            resetFlag={resetFlag}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard dashboard_2">
      <Toaster />
      <div className="full_container">
        <div className="inner_container">
          <Sidebar
            setActiveComponent={handleSetActiveComponent}
            isAdmin={isAdmin}
            isSuperAdmin={isSuperAdmin}
          />
          <div id="content" style={{}}>
            <Topbar userInfo={userInfo} org={selectedOrg} />
            <div className="content">
              <div className="midde_cont">
                <div className="container-fluid">{renderComponent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Sidebar({ setActiveComponent, isAdmin, isSuperAdmin }) {
  const navigate = useNavigate();


  // const handleLogout = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
  //       method: "POST",

  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //     });

  //     if (response.ok) {
  //       // Log out successfully to log in page
  //       localStorage.removeItem("email");
  //       localStorage.removeItem("password");
  //       localStorage.removeItem("rememberMe");
  //       localStorage.removeItem("userInfo");
  //       // Refresh the page or any other necessary action after logout
  //       navigate("/");
  //     } else {
  //       console.log("Logout failed.");
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  return (
    <nav id="sidebar" style={{ zIndex: 1049 }}>
      <div className="sidebar_blog_1">
        <div className="sidebar-header">
          <div
            className="logo_section"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
            }}
          >
            <img
              className="img-responsive"
              src={carewindow}
              alt="carewindow"
              style={{ height: "52px" }}
            />
          </div>
        </div>
        <div className="sidebar_user_info">
          <div className="user_profle_side">
            <div className="user_info"></div>
          </div>
        </div>
      </div>
      <div className="sidebar_blog_2">
        {/* <h4>General</h4> */}
        <ul className="list-unstyled components" style={{ marginTop: "30px" }}>
          {/* <li onClick={() => setActiveComponent('Dashboard')}>
            <a href="#dashboard"><i className="fa fa-dashboard yellow_color"></i> <span>Dashboard</span></a>
          </li> */}

          {/* <li onClick={() => setActiveComponent("Dashboard")}>
            <a href="#adddevices">
              <i className="fa fa-dashboard yellow_color"></i>{" "}
              <span>Dashboard</span>
            </a>
          </li> */}
          <li onClick={() => setActiveComponent("Add Devices")}>
            <a href="#adddevices">
              <i className="fa fa-plus-square-o orange_color"></i>{" "}
              <span>Add Devices</span>
            </a>
          </li>
          <li onClick={() => setActiveComponent("Manage Devices")}>
            <a href="#managedevices">
              <i className="fa fa-laptop purple_color"></i>{" "}
              <span>Manage Devices</span>
            </a>
          </li>
          <li onClick={() => setActiveComponent("Policy Groups")}>
            <a href="#managedevices">
              <i className="fa fa-file-o white_color"></i>{" "}
              <span>Policy Groups</span>
            </a>
          </li>
          <li onClick={() => setActiveComponent("Announcements")}>
            <a href="#announcements">
              <i className="fa fa-bell-o blue2_color"></i>{" "}
              <span>Announcements </span>
            </a>
          </li>
          {/* <li onClick={() => setActiveComponent('Event Calendar')}>
            <a href="#calendar"><i className="fa fa-calendar purple_color2"></i> <span>Event Calendar</span></a>
          </li> */}
          <li onClick={() => setActiveComponent("Manage Users")}>
            <a href="#manageusers">
              <i className="fa fa-users yellow_color"></i>{" "}
              <span>Manage Users</span>
            </a>
          </li>
          {isAdmin || isSuperAdmin ? (
            <li
              style={{ marginTop: "50px" }}
              onClick={() => navigate("/organise")}
            >
              <a href="#logout">
                <i className="fa fa-sign-out red_color"></i>{" "}
                <span>Back to Admin Page</span>
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    </nav>
  );
}

function Topbar({ userInfo, org }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const truncateEmail = (email) => {
    if (email.length <= 20) {
      return email;
    }
    const start = email.substring(0, 20);

    return `${start}.....`;
  };

  return (
    <div className="topbar" style={{ zIndex: 1048 }}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="full">
          {/* <button type="button" id="sidebarCollapse" className="sidebar_toggle" style={{ height: '61px', width: '70px' }}></button> */}
          <div
            style={{
              display: "inline-block",
              marginLeft: "25px",
              marginTop: "10px",
            }}
          >
            <h1 style={{ color: "white" }}>
              {org?.name || "Organisation Name"}
            </h1>
          </div>
          <div className="right_topbar">
            <div className="icon_info">
              {/* <ul>
                <li><a href="#"><i className="fa fa-bell-o"></i><span className="badge">2</span></a></li>
                <li><a href="#"><i className="fa fa-question-circle"></i></a></li>
                <li><a href="#"><i className="fa fa-envelope-o"></i><span className="badge">3</span></a></li>
              </ul> */}
              <ul className="user_profile_dd">
                <li className={`dropdown ${isOpen ? "show" : ""}`}>
                  <a
                    className="dropdown-toggle"
                    onClick={toggleDropdown}
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-user black_color"></i>
                    <span className="name_user">
                      {userInfo ? userInfo.name : "Guest"}
                    </span>
                  </a>
                  <div className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                    <span style={{ color: "black" }}>
                      {userInfo
                        ? truncateEmail(userInfo.email)
                        : "Email address"}
                    </span>
                    <a
                      className="dropdown-item"
                      onClick={async () => {
                        try {
                          const response = await fetch(
                            `${process.env.REACT_APP_API_URL}/logout`,
                            {
                              method: "POST",

                              headers: {
                                "Content-Type": "application/json",
                                'portalscope': 'b3NMWOVzfdRUjrW',
                                'validatereq': generateRandomString(30)
                              },
                              credentials: "include",
                            }
                          );

                          if (response.ok) {
                            localStorage.removeItem("email");
                            localStorage.removeItem("password");
                            localStorage.removeItem("rememberMe");
                            localStorage.removeItem("userInfo");
                            navigate("/");
                          } else {
                            console.log("Logout failed.");
                          }
                        } catch (error) {
                          console.log("Error:", error);
                        }
                      }}
                    >
                      <span>Log Out</span> <i className="fa fa-sign-out"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

function Dashboard({ org }) {

  const devices = [
    { id: 1, name: 'Device 1', status: 'Active', policyGroup: 'Group A' },
    { id: 2, name: 'Device 2', status: 'Inactive', policyGroup: 'None' },
    // Sample data
  ];

  // Calculate the status count
  const statusCount = devices.reduce((acc, device) => {
    acc[device.status] = (acc[device.status] || 0) + 1;
    return acc;
  }, {});

  // Prepare data for Recharts
  const pieData = Object.keys(statusCount).map(status => ({
    name: status,
    value: statusCount[status],
  }));


  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];






  const [policyGroups, setPolicyGroups] = useState([]);
  const [orgDevices, setOrgDevices] = useState([]);

  const fetchPolicyGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/policy_by_org?orgid=${org.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filterPolicys = data.filter(
          (policy) => policy.status === "Active"
        );
        setPolicyGroups(filterPolicys);

      } else {
        console.log("Failed to fetch policy groups.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchOrgDevices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_org_devices?OrgCode=${org.code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const filteredDevices = data.filter(
          (device) => device.policyName === null
        );
        setOrgDevices(filteredDevices);

      } else {
        console.log("Failed to fetch devices.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchPolicyGroups();
    fetchOrgDevices();
  }, [org]);




  return (
    <div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-4">
          <div className="card" style={{ height: 140 }}>
            <div className="card-body">
              <h5 className="card-title">Total Devices Enrolled</h5>
              <p className="card-text">
                {orgDevices.length}
              </p>
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card" style={{ height: 140 }}>
            <div className="card-body">
              <h5 className="card-title">Policy Groups</h5>
              <p className="card-text">
                {policyGroups.length}
              </p>
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4" >
          <div className="card" style={{ height: 140 }}>
            <div className="card-body">
              <h5 className="card-title"> Recently called Devices</h5>

              <ul>
                {orgDevices.map((device, index) => (
                  <li className="card-text" key={index}>{device.name}</li>
                ))}
              </ul>
              {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
            </div>
          </div>
        </div>

      </div>

      <div>
        <PieChart width={400} height={400}>
          <Pie
            data={pieData}
            cx={180}
            cy={180}
            labelLine={false}
            outerRadius={120}
            fill="#8884d8"
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>


  );








  // return (
  //   <div className="midde_cont">
  //     <div
  //       className="container-fluid"
  //       style={{ paddingLeft: "0", paddingRight: "0" }}
  //     >
  //       <div className="row column_title">
  //         <div className="col-md-12">
  //           <div className="page_title">
  //             <h2>Dashboard</h2>
  //           </div>
  //         </div>
  //       </div>

  //       <blockquote className="blockquote text-right">
  //         <p className="display-2">More contents are coming soon!</p>
  //         <footer className="blockquote-footer">CareWindow Team</footer>
  //       </blockquote>
  //     </div>
  //   </div>
  // );


}

function AddDevices({ setActiveComponent, org }) {
  return (
    <div className="midde_cont">
      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title">
              <h2>Add Devices</h2>
            </div>
          </div>
        </div>

        <div className="row column1">
          <div className="col-md-12">
            <div className="white_shd full margin_bottom_30 add_devices_instruction_list_wrap">
              <h4 className="add_devices_instruction_heading">
                Adding a CareWindow is a simple, 3-step process. Follow the
                instructions below to enrol one or more CareWindows in your
                organisation.
              </h4>
              <ol className="add_devices_instruction_list">
                <li className="add_devices_instruction">
                  On each CareWindow device, tap Pair, then tap "Allow an
                  organisation to manage this device".
                </li>

                <li className="add_devices_instruction">
                  <label>
                    In the text field that appears, enter your organisation
                    code:
                    <b style={{ paddingLeft: "5px" }}>{org.code}</b> and pin:
                    <b style={{ paddingLeft: "5px" }}>{org.pin}</b> .
                  </label>
                </li>

                <li className="add_devices_instruction">
                  <div>
                    When the device asks you to confirm the enrolment, tap
                    “Confirm”. The new device should now appear under{" "}
                    <a
                      href="#"
                      onClick={() => setActiveComponent("Manage Devices")}
                      className="link-primary"
                    >
                      Manage Devices
                    </a>
                    .
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ManageDevices({ settingsFrom, selectedOrg, userInfo, resetFlag }) {
  const [selectedTab, setSelectedTab] = useState("All Devices");
  const [selectedUser, setSelectedUser] = useState(null);
  const [cwusers, setCwUsers] = useState([]);
  const [callingDeviceId, setCallingDeviceId] = useState(null);
  const [callingAccountId, setCallingAccountId] = useState(null);
  const [callerName, setCallerName] = useState("");
  const [showVideoCall, setShowVideoCall] = useState(false);
  const [token, setToken] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDevice, setDeleteDevice] = useState(null);
  const [showCallModal, setShowCallModal] = useState(false);
  const [callUser, setCallUser] = useState(null);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewVideoTrack, setPreviewVideoTrack] = useState(null);
  const [previewAudioTrack, setPreviewAudioTrack] = useState(null);
  const [isPreviewCameraEnabled, setIsPreviewCameraEnabled] = useState(true);
  const [isPreviewMicrophoneEnabled, setIsPreviewMicrophoneEnabled] = useState(true);
  const previewVideoRef = useRef(null);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioInput, setSelectedAudioInput] = useState(null);
  const [selectedAudioOutput, setSelectedAudioOutput] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);




 
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        // Fetch and modify audio input devices (microphones)
        const audioDevices = await AgoraRTC.getMicrophones();
        const filteredAudioInputDevices = audioDevices
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setAudioInputDevices(filteredAudioInputDevices);
        console.log(filteredAudioInputDevices);
  
        // Fetch and modify audio output devices (speakers/headphones)
        const audioOutput = await AgoraRTC.getPlaybackDevices();
        const filteredAudioOutputDevices = audioOutput
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setAudioOutputDevices(filteredAudioOutputDevices);
        console.log(filteredAudioOutputDevices);
  
        // Fetch and modify video devices (cameras)
        const cameraDevices = await AgoraRTC.getCameras();
        const filteredCameraDevices = cameraDevices
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setVideoDevices(filteredCameraDevices);
        console.log(filteredCameraDevices);
  
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };
  
    fetchDevices();
  
    // Device change event listener
    const handleDeviceChange = async () => {
      console.log("Device change detected, refetching devices...");
      await fetchDevices(); 
    };
  
    // Add event listener for device changes
    navigator.mediaDevices.ondevicechange = handleDeviceChange;
  
    return () => {
      // Remove the event listener when the component unmounts
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);
  

  useEffect(() => {
    fetchCwUsers(selectedOrg);
  }, [selectedOrg]);

  useEffect(() => {
    setSelectedUser(null);
  }, [resetFlag]);

  useEffect(() => {
    return () => {
      cleanupPreviewTracks();
    };
  }, []);

  const cleanupPreviewTracks = async () => {
    if (previewVideoTrack) {
      previewVideoTrack.stop();
      previewVideoTrack.close();
      setPreviewVideoTrack(null);
    }
    if (previewAudioTrack) {
      previewAudioTrack.stop();
      previewAudioTrack.close();
      setPreviewAudioTrack(null);
    }

    // Request and immediately stop an empty audio and video stream
    try {
      const emptyStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      emptyStream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.error("Error resetting media devices:", error);
    }
  };

  const fetchCwUsers = async (selectedOrg) => {
    try {
      if (!selectedOrg) return;
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_org_devices?OrgCode=${selectedOrg.code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCwUsers(data);
      } else {
        console.log("Failed to fetch CareWindow users.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleRowClick = (user) => {
    setSelectedUser(user);
  };

  const handleBackClick = () => {
    setSelectedUser(null);
  };

  const handleDeleteDeviceClick = (device) => {
    setDeleteDevice(device);
    setShowDeleteModal(true);
  };

  const handleDeleteDeviceCancel = () => {
    setShowDeleteModal(false);
    setDeleteDevice(null);
  };

  const handleUnenrollClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/unlink_device_org?id=${deleteDevice.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        toast.success("Device successfully unenrolled.");
        fetchCwUsers(selectedOrg);
      } else {
        toast.error("Failed to unenroll the device.");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setShowDeleteModal(false);
    setDeleteDevice(null);
  };



  const handleDeviceChange = (event, setter) => {
    setter(event.target.value);
    // console.log(event.target.value);
  };


  useEffect(() => {
    const buildPreview = async () => {
      try {
        let audioTrack;
        let videoTrack;
        cleanupPreviewTracks();
        if (selectedAudioInput != null) {
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedAudioInput });
        }
        else {
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        }
        if (selectedCamera != null) {
          videoTrack = await AgoraRTC.createCameraVideoTrack({ cameraId: selectedCamera });
        }
        else {
          videoTrack = await AgoraRTC.createCameraVideoTrack();

        }

        setPreviewAudioTrack(audioTrack);
        setPreviewVideoTrack(videoTrack);
        if (previewVideoRef.current) {
          videoTrack.play(previewVideoRef.current);
        }
      } catch (error) {
        console.error("Failed to create preview tracks", error);
        setIsPreviewCameraEnabled(false);
        setIsPreviewMicrophoneEnabled(false);
      }
    };

    if (isPreviewModalOpen){
    buildPreview();}
  }, [selectedAudioInput, selectedCamera, selectedAudioOutput]);

  const handleCallClick = async (user) => {
    setCallUser(user);
    setShowPreviewModal(true);
    setIsPreviewCameraEnabled(true);
    setIsPreviewMicrophoneEnabled(true);
    setIsPreviewModalOpen(true);

    try {
      let audioTrack;
      let videoTrack;
      cleanupPreviewTracks();
      if (selectedAudioInput != null) {
        audioTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedAudioInput });
      }
      else {
        audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      }
      if (selectedCamera != null) {
        videoTrack = await AgoraRTC.createCameraVideoTrack({ cameraId: selectedCamera });
      }
      else {
        videoTrack = await AgoraRTC.createCameraVideoTrack();

      }

      setPreviewAudioTrack(audioTrack);
      setPreviewVideoTrack(videoTrack);
      if (previewVideoRef.current) {
        videoTrack.play(previewVideoRef.current);
      }
    } catch (error) {
      console.error("Failed to create preview tracks", error);
      setIsPreviewCameraEnabled(false);
      setIsPreviewMicrophoneEnabled(false);
    }
  };

  const handleStartCall = async () => {
    setShowPreviewModal(false);






    cleanupPreviewTracks();
    // Prepare call data
    const callData = {
      userInfo,
      callingDeviceId: callUser.id,
      callingAccountId: callUser.accountId,
      callerName: callUser.name,
      initialCameraEnabled: isPreviewCameraEnabled,
      initialMicrophoneEnabled: isPreviewMicrophoneEnabled,
      initialAudio: selectedAudioInput,
      initialVideo: selectedCamera,
      initialAudioOutput: selectedAudioOutput,
    };

    // Convert call data to a Base64 encoded string
    const encodedCallData = btoa(JSON.stringify(callData));

    // Open a new tab with the video call page
    const videoCallTab = window.open(`/video-call?data=${encodedCallData}`, '_blank');

    if (videoCallTab) {
      // Focus on the new tab
      videoCallTab.focus();
    } else {
      // If the tab couldn't be opened (e.g., blocked by popup blocker)
      toast.error("Unable to open video call tab. Please check your popup blocker settings.");
    }

    // Don't clean up preview tracks here, let the new tab handle it
  };

  const handlePreviewCancel = async () => {
    setShowPreviewModal(false);
    await cleanupPreviewTracks();
    setCallUser(null);
  };

  const togglePreviewCamera = async () => {
    if (previewVideoTrack) {
      await previewVideoTrack.setEnabled(!isPreviewCameraEnabled);
      setIsPreviewCameraEnabled(!isPreviewCameraEnabled);
    }
  };

  const togglePreviewMicrophone = async () => {
    if (previewAudioTrack) {
      await previewAudioTrack.setEnabled(!isPreviewMicrophoneEnabled);
      setIsPreviewMicrophoneEnabled(!isPreviewMicrophoneEnabled);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCwUsers = cwusers.filter(
    (user) =>
      (user.name &&
        user.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.serialNo &&
        user.serialNo.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.policyName &&
        user.policyName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.orgEnrollDate &&
        new Date(user.orgEnrollDate)
          .toLocaleDateString("en-GB")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );


  const buttonStyle = (enabled) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    margin: "0 10px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: enabled ? "#1976D2" : "#1976D2",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    color: "white",
  });

  return (
    <div className="midde_cont">
      <div className="container-fluid" style={{ paddingLeft: "0", paddingRight: "0" }}>
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title" style={{ display: "flex", alignItems: "center" }}>
              {selectedUser ? (
                <ArrowBackRoundedIcon
                  onClick={handleBackClick}
                  sx={{
                    fontSize: "2rem",
                    color: "black",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              ) : null}
              <h2 style={{ flex: "1" }}>Manage Devices</h2>
            </div>
          </div>
        </div>

        {selectedUser ? (
          <UserSetting
            user={selectedUser}
            onBackClick={handleBackClick}
            settingsFrom={settingsFrom}
          />
        ) : (
          <>
            <div className="white_shd full margin_bottom_30">
              <div className="full inner_elements">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab_style2">
                      <div className="tabbar padding_infor_info">
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className={`tab-pane fade ${selectedTab === "All Devices" ? "show active" : ""
                              }`}
                            id="nav-all-devices"
                            role="tabpanel"
                            aria-labelledby="nav-all-devices-tab"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-wrap">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control w-50"
                                      placeholder="Search devices..."
                                      value={searchQuery}
                                      onChange={handleSearchInputChange}
                                    />
                                  </div>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr key="headers">
                                        <th key="Name" style={{ fontWeight: "700" }}>
                                          CareWindow User
                                        </th>
                                        <th key="Email">Device S/N</th>
                                        <th key="Date">Date Enrolled</th>
                                        <th key="Poicy">Policy Group</th>
                                        <th key="Action">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredCwUsers.map((user) => (
                                        <tr
                                          key={user.id}
                                          onClick={() => handleRowClick(user)}
                                        >
                                          <td>
                                            {user.profilePicture ? (
                                              <img
                                                className="user-photo"
                                                src={`data:image/png;base64, ${user.profilePicture}`}
                                                alt={user.name}
                                              />
                                            ) : (
                                              <img
                                                className="user-photo"
                                                src={deviceProfile}
                                                alt="Default profile"
                                              />
                                            )}
                                            {user.name}
                                          </td>
                                          <td style={{ verticalAlign: "middle" }}>
                                            {user.serialNo}
                                          </td>
                                          <td style={{ verticalAlign: "middle" }}>
                                            {new Date(user.orgEnrollDate).toLocaleDateString("en-GB")}
                                          </td>
                                          <td style={{ verticalAlign: "middle" }}>
                                            {user.policyName ? user.policyName : "(none)"}
                                          </td>
                                          <td style={{ verticalAlign: "middle" }}>
                                            <PhoneCallIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: "black",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleCallClick(user);
                                              }}
                                            />
                                            <TrashIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: "black",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteDeviceClick(user);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {showDeleteModal && (
          <div className="modal fade show" style={{ display: "block" }} aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title">Delete Device</h1>
                  <button
                    type="button"
                    className="close-button"
                    onClick={handleDeleteDeviceCancel}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to unenroll{" "}
                    <strong>{deleteDevice.name}</strong>?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDeleteDeviceCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleUnenrollClick}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}



{showPreviewModal && (
  <div
    className="modal fade show"
    style={{
      display: "block",
      backgroundColor: "rgba(0,0,0,0.5)",
      overflowY: "auto", // 确保背景可滚动以防弹窗内容过高
      
      paddingBottom: "20px",
    }}
    aria-modal="true"
    role="dialog"
  >
    <div
      className="modal-dialog modal-lg modal-dialog-centered"
      role="document"
      style={{
        maxWidth: "90%", // 根据需要调整宽度
        margin: "auto",
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "#f0f0f0",
          width: "100%",
          padding: "15px",
          maxHeight: "90vh", // 最大高度为视口高度的90%
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="modal-header" style={{ flexShrink: 0 }}>
          <h1 className="modal-title">Video Call Preview</h1>
          <button
            type="button"
            className="close-button"
            onClick={handlePreviewCancel}
            aria-label="Close"
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="modal-body"
          style={{
            padding: "15px",
            overflowY: "auto", // 允许内容滚动
            flexGrow: 1,
          }}
        >
          <div
            style={{
              width: "100%",
              aspectRatio: "16/9",
              backgroundColor: "black",
              position: "relative",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <div ref={previewVideoRef} style={{ width: "100%", height: "100%" }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <button onClick={togglePreviewMicrophone} style={buttonStyle(isPreviewMicrophoneEnabled)}>
              {isPreviewMicrophoneEnabled ? <KeyboardVoiceRounded /> : <MicOffRounded />}
            </button>
            <button onClick={togglePreviewCamera} style={buttonStyle(isPreviewCameraEnabled)}>
              {isPreviewCameraEnabled ? <VideocamRounded /> : <VideocamOffRounded />}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            {/* Microphone Selection */}
            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-input-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Microphone
              </label>
              <select
                id="audio-input-select"
                value={selectedAudioInput}
                onChange={(e) => handleDeviceChange(e, setSelectedAudioInput)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {audioInputDevices[0]?.label || "No Device"}
                </option>
                {audioInputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Input"}
                  </option>
                ))}
              </select>
            </div>
            {/* Speaker Selection */}
            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-output-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Speaker
              </label>
              <select
                id="audio-output-select"
                value={selectedAudioOutput}
                onChange={(e) => handleDeviceChange(e, setSelectedAudioOutput)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {audioOutputDevices[0]?.label || "No Device"}
                </option>
                {audioOutputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Output"}
                  </option>
                ))}
              </select>
            </div>
            {/* Camera Selection */}
            <div style={{ width: "100%", maxWidth: "350px" }}>
              <label htmlFor="camera-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Camera
              </label>
              <select
                id="camera-select"
                value={selectedCamera}
                onChange={(e) => handleDeviceChange(e, setSelectedCamera)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {videoDevices[0]?.label || "No Device"}
                </option>
                {videoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Camera"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0,
          }}
        >
          <button type="button" className="btn btn-secondary" onClick={handlePreviewCancel}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={handleStartCall}>
            Start Call
          </button>
        </div>
      </div>
    </div>
  </div>
)}





      </div>
    </div>
  );
}

function PolicyGroups({
  user,
  onApplyToAllDevices,
  onApplyToThisDevice,
  settingsFrom,
  org,
  resetFlag,
}) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Devices in Group");
  const [selectedPolicyTab, setSelectedPolicyTab] = useState("Call Settings");
  const [policyGroups, setPolicyGroups] = useState([]);
  const [showAddPolicyGroupModal, setShowAddPolicyGroupModal] = useState(false);
  const [newPolicyGroup, setNewPolicyGroup] = useState({ name: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [policyDevices, setPolicyDevices] = useState([]);
  const [showEditPolicyGroupModal, setShowEditPolicyGroupModal] =
    useState(false);
  const [editedPolicyGroup, setEditedPolicyGroup] = useState({
    id: "",
    name: "",
    status: "",
    noOfDevices: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [policyGroupSearchQuery, setPolicyGroupSearchQuery] = useState("");
  const [policyDeviceSearchQuery, setPolicyDeviceSearchQuery] = useState("");

  useEffect(() => {
    fetchPolicyGroups();
  }, [org.id]);

  useEffect(() => {
    setSelectedGroup(null);
  }, [resetFlag]);

  useEffect(() => {
    if (selectedGroup) {
      fetchPolicyDevices();
    }
  }, [selectedGroup]);

  const fetchPolicyGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/policy_by_org?orgid=${org.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filterPolicys = data.filter(
          (policy) => policy.status === "Active"
        );
        setPolicyGroups(filterPolicys);

      } else {
        console.log("Failed to fetch policy groups.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchOrgDevices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_org_devices?OrgCode=${org.code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        const filteredDevices = data.filter(
          (device) => device.policyName === null
        );
        setDevices(filteredDevices);

      } else {
        console.log("Failed to fetch devices.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchPolicyDevices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/devices_by_policy?PolicyId=${selectedGroup.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPolicyDevices(data);
      } else {
        console.log("Failed to fetch policy devices.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    if (group === null) {
      fetchPolicyGroups();
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handlePolicyTabClick = (tab) => {
    setSelectedPolicyTab(tab);
  };

  const handleAddClick = () => {
    setShowAddPolicyGroupModal(true);
  };

  const handleInputChange = (e) => {
    setNewPolicyGroup({ ...newPolicyGroup, [e.target.name]: e.target.value });
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/create_policy`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({
            organizationId: org.id,
            name: newPolicyGroup.name,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        setNewPolicyGroup({ name: "" });
        setShowAddPolicyGroupModal(false);
        toast.success("Policy group created!");
        fetchPolicyGroups();
      } else {
        toast.error("Failed to create policy group.");
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred while creating the policy group.");
    }
  };

  const handleAddCancel = () => {
    setShowAddPolicyGroupModal(false);
  };

  const handleAddDeviceClick = () => {
    setShowAddDeviceModal(true);
    fetchPolicyDevices();
    fetchOrgDevices();
  };

  const handleDeviceSelect = (deviceId) => {
    if (selectedDevices.includes(deviceId)) {
      setSelectedDevices(selectedDevices.filter((id) => id !== deviceId));
    } else {
      setSelectedDevices([...selectedDevices, deviceId]);
    }
  };

  const handleAddDevicesToGroup = async () => {
    try {
      let hasError = false;
      for (const deviceId of selectedDevices) {
        const response = await fetch(
          `${process.env.REACT_APP_POLICY_API_URL}/add_device_to_policy?id=${deviceId}&PolicyId=${selectedGroup.id}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: "include",
          }
        );

        if (response.status === 500) {
          const errorData = await response.json();
          toast.error(errorData.detail);
          hasError = true;
        } else if (!response.ok) {
          console.log(`Failed to add device ${deviceId} to policy group.`);
          hasError = true;
        }
      }

      setSelectedDevices([]);
      setShowAddDeviceModal(false);

      if (!hasError) {
        toast.success("Devices added to policy group!");
      }

      fetchPolicyDevices();
    } catch (error) {
      console.log("Error:", error);
      toast.error(
        "An error occurred while adding devices to the policy group."
      );
    }
  };
  // const handleDeleteGroupClick = (groupId) => {
  //   setDeleteGroupId(groupId);
  //   setShowDeleteModal(true);
  // };

  const handleDeleteGroupConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/delete_policy/${deleteGroupId}`,
        {
          method: "DELETE",

          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedPolicyGroups = policyGroups.filter(
          (group) => group.id !== deleteGroupId
        );
        setPolicyGroups(updatedPolicyGroups);
        setDeleteGroupId(null);
        toast.success("Policy group deleted!");
        setShowDeleteModal(false);
        fetchPolicyGroups();
      } else {
        console.log("Failed to delete policy group.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDeleteGroupCancel = () => {
    setShowDeleteModal(false);
  };

  const handleRemoveClick = async (deviceId, policyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/remove_device_from_policy?id=${deviceId}&PolicyId=${policyId}`,
        {
          method: "POST",

          credentials: "include",
        }
      );

      if (response.ok) {
        toast.success("Device removed successfully!");
        fetchPolicyDevices();
      } else {
        console.log("Failed to remove device.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleEditClick = (group) => {
    setEditedPolicyGroup({
      id: group.id,
      name: group.name,
      status: group.status,
      noOfDevices: group.noOfDevices,
    });
    setShowEditPolicyGroupModal(true);
  };

  const handleEditInputChange = (e) => {
    setEditedPolicyGroup({
      ...editedPolicyGroup,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/update_policy`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify(editedPolicyGroup),
          credentials: "include",
        }
      );

      if (response.ok) {
        setShowEditPolicyGroupModal(false);
        toast.success("Policy group updated!");
        fetchPolicyGroups();
      } else {
        toast.error("Failed to update policy group.");
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred while updating the policy group.");
    }
  };

  const handleEditCancel = () => {
    setShowEditPolicyGroupModal(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDevices = devices.filter(
    (device) =>
      (device.name &&
        device.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (device.serialNo &&
        device.serialNo.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // const handlePolicyGroupSearchInputChange = (e) => {
  //   setPolicyGroupSearchQuery(e.target.value);
  // };

  const handlePolicyDeviceSearchInputChange = (e) => {
    setPolicyDeviceSearchQuery(e.target.value);
  };

  const filteredPolicyGroups = policyGroups.filter((group) =>
    group.name.toLowerCase().includes(policyGroupSearchQuery.toLowerCase())
  );

  const filteredPolicyDevices = policyDevices.filter(
    (device) =>
      device.name
        .toLowerCase()
        .includes(policyDeviceSearchQuery.toLowerCase()) ||
      device.serialNo
        .toLowerCase()
        .includes(policyDeviceSearchQuery.toLowerCase())
  );

  return (
    <div className="midde_cont">
      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="row column_title">
          <div className="col-md-12">
            <div
              className="page_title"
              style={{ display: "flex", alignItems: "center" }}
            >
              {selectedGroup ? (
                <ArrowBackRoundedIcon
                  onClick={() => handleGroupClick(null)}
                  sx={{
                    fontSize: "2rem",
                    color: "black",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              ) : null}
              <h2 style={{ flex: "1" }}>Policy Groups</h2>
            </div>
          </div>
        </div>

        <div className="white_shd full margin_bottom_30">
          <div className="full inner_elements">
            <div className="row">
              <div className="col-md-12">
                {selectedGroup ? (
                  <div className="padding_infor_info">
                    <h3 style={{ marginLeft: "30px" }}>{selectedGroup.name}</h3>
                    <div>
                      <div className="tab_style1">
                        <div className="tabbar padding_infor_info">
                          <nav>
                            <div
                              className="nav nav-tabs"
                              id="nav-tab"
                              role="tablist"
                            >
                              <div
                                className={`nav-item nav-link ${selectedTab === "Devices in Group"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleTabClick("Devices in Group")
                                }
                              >
                                Devices in Group
                              </div>

                              <div
                                className={`nav-item nav-link ${selectedTab === "Policy Settings"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() =>
                                  handleTabClick("Policy Settings")
                                }
                              >
                                Policy Settings
                              </div>
                            </div>
                          </nav>
                        </div>
                      </div>

                      <div className="tab-content">
                        {selectedTab === "Policy Settings" && (
                          <div className="row">
                            <div className="col-md-3">
                              <nav>
                                <div
                                  className="nav flex-column nav-pills"
                                  id="nav-tab"
                                  role="tablist"
                                  style={{ paddingLeft: "25px" }}
                                >
                                  <div
                                    className={`nav-item nav-link ${selectedPolicyTab === "Call Settings"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      handlePolicyTabClick("Call Settings")
                                    }
                                    style={{ width: "140px" }}
                                  >
                                    Call Settings
                                  </div>
                                  <div
                                    className={`nav-item nav-link ${selectedPolicyTab === "Do Not Disturb"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      handlePolicyTabClick("Do Not Disturb")
                                    }
                                    style={{ width: "140px" }}
                                  >
                                    Do Not Disturb
                                  </div>

                                  <div
                                    className={`nav-item nav-link ${selectedPolicyTab === "Pairing"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      handlePolicyTabClick("Pairing")
                                    }
                                    style={{ width: "140px" }}
                                  >
                                    Pairing
                                  </div>
                                </div>
                              </nav>
                            </div>
                            <div className="col-md-9">
                              <div className="tab-content">
                                {selectedPolicyTab === "Pairing" && (
                                  <div>
                                    <PairingTab
                                      user={user}
                                      settingsFrom={settingsFrom}
                                      policy={selectedGroup}
                                    />
                                  </div>
                                )}
                                {selectedPolicyTab === "Do Not Disturb" && (
                                  <div>
                                    <DoNotDisturb
                                      user={user}
                                      settingsFrom={settingsFrom}
                                      policy={selectedGroup}
                                    />
                                  </div>
                                )}
                                {selectedPolicyTab === "Call Settings" && (
                                  <div>
                                    <SettingsTab
                                      onApplyToAllDevices={onApplyToAllDevices}
                                      onApplyToThisDevice={onApplyToThisDevice}
                                      settingsFrom={settingsFrom}
                                      policy={selectedGroup}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedTab === "Devices in Group" && (
                          <div className="table-wrap padding_infor_info">
                            <button
                              className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                              style={{
                                marginBottom: "20px",
                                marginTop: "20px",
                              }}
                              onClick={handleAddDeviceClick}
                            >
                              + Add Device
                            </button>
                            <div className="form-group w-50">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search devices..."
                                value={policyDeviceSearchQuery}
                                onChange={handlePolicyDeviceSearchInputChange}
                              />
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>CareWindow User</th>
                                  <th>Device S/N</th>
                                  <th>Date Enrolled</th>

                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredPolicyDevices.map((device) => (
                                  <tr key={device.id}>
                                    <td>
                                      {device.profilePicture ? (
                                        <img
                                          className="user-photo"
                                          src={`data:image/png;base64, ${device.profilePicture}`}
                                          alt={device.name}
                                        />
                                      ) : (
                                        <img
                                          className="user-photo"
                                          src={deviceProfile}
                                          alt="Default profile"
                                        />
                                      )}
                                      {device.name}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {device.serialNo}
                                    </td>
                                    {/* {user.deviceSN} */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      {new Date(
                                        device.orgEnrollDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    {/* {user.roomNumber} */}
                                    {/*<td style={{ verticalAlign: "middle" }}>*/}
                                    {/*  lastCall*/}
                                    {/*</td>*/}
                                    {/* {user.lastCall} */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      {/* <PhoneCallIcon
                                        sx={{
                                          fontSize: "1rem",
                                          color: "black",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        // onClick={(e) => {
                                        //   e.stopPropagation();
                                        //   handleCallClick(user.id, user.accountId, user.name);
                                        // }}
                                      /> */}
                                      {/* <EditIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: "black",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                              }}
                                            /> */}
                                      <TrashIcon
                                        sx={{
                                          fontSize: "1rem",
                                          color: "black",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveClick(
                                            device.id,
                                            selectedGroup.id
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-wrap padding_infor_info">
                    {/* <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search policy groups..."
                      value={policyGroupSearchQuery}
                      onChange={handlePolicyGroupSearchInputChange}
                    />
                  </div> */}
                    <button
                      className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                      onClick={handleAddClick}
                    >
                      + Add Policy Group
                    </button>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Group Name</th>
                          <th>Device numbers</th>
                          <th>Last Edit</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPolicyGroups.map((group) => (
                          <tr
                            key={group.id}
                            onClick={() => handleGroupClick(group)}
                          >
                            <td>{group.name}</td>
                            <td>{group.noOfDevices}</td>
                            <td>
                              {new Date(group.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            <td>
                              <EditIcon
                                sx={{
                                  fontSize: "1rem",
                                  color: "black",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(group);
                                }}
                              />
                              {/* <TrashIcon
                                sx={{
                                  fontSize: "1rem",
                                  color: "black",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteGroupClick(group.id);
                                }} 
                              />*/}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddPolicyGroupModal && (
        <div className="modal" tabIndex={"-1"} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Add Policy Group</h1>
                <button className="close-button" onClick={handleAddCancel}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form className="contact-form">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      value={newPolicyGroup.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="modal-footer">
                    <input
                      type="submit"
                      className="btn cur-p btn-light"
                      value="Cancel"
                      onClick={handleAddCancel}
                    />
                    <input
                      type="submit"
                      className="btn cur-p btn-dark"
                      value="Add"
                      onClick={handleAddSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Delete Policy Group</h1>
                <button
                  type="button"
                  className="close-button"
                  onClick={handleDeleteGroupCancel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete{" "}
                  <strong>
                    {
                      policyGroups.find((group) => group.id === deleteGroupId)
                        ?.name
                    }
                  </strong>
                  ?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteGroupCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteGroupConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddDeviceModal && (
        <div className="modal" tabIndex={"-1"} role="dialog">
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{ height: "98vh", minWidth: "700px" }}
            >
              <div className="modal-header">
                <h1 className="modal-title">Add Devices to Policy Group</h1>
                <button
                  className="close-button"
                  onClick={() => setShowAddDeviceModal(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control w-50"
                    placeholder="Search devices..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                </div>
                <table
                  className="table table-hover table-wrap"
                  style={{ margin: "40px 0" }}
                >
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Device S/N</th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDevices.map((device) => (
                      <tr key={device.id}>
                        <td>{device.name}</td>
                        <td>{device.serialNo}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedDevices.includes(device.id)}
                            onChange={() => handleDeviceSelect(device.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowAddDeviceModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddDevicesToGroup}
                  disabled={selectedDevices.length === 0}
                  style={{
                    opacity: selectedDevices.length === 0 ? 0.5 : 1,
                    cursor:
                      selectedDevices.length === 0 ? "not-allowed" : "pointer",
                  }}
                >
                  Add Devices
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditPolicyGroupModal && (
        <div className="modal" tabIndex={"-1"} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Edit Policy Group</h1>
                <button className="close-button" onClick={handleEditCancel}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form className="contact-form">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      value={editedPolicyGroup.name}
                      onChange={handleEditInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={editedPolicyGroup.status}
                      onChange={handleEditInputChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  {editedPolicyGroup.status === "Inactive" && (
                    <div className="alert alert-warning" role="alert">
                      Are you sure you want to set the "{editedPolicyGroup.name}
                      " as inactive? This action will release{" "}
                      {editedPolicyGroup.noOfDevices} devices out of the policy
                      group and delete this policy group.
                    </div>
                  )}
                  <div className="modal-footer">
                    <input
                      type="submit"
                      className="btn cur-p btn-light"
                      value="Cancel"
                      onClick={handleEditCancel}
                    />
                    <input
                      type="submit"
                      className="btn cur-p btn-dark"
                      value="Save"
                      onClick={handleEditSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function UserSetting({
  user,
  onBackClick,
  onApplyToAllDevices,
  onApplyToThisDevice,
  settingsFrom,
}) {
  const [selectedTab, setSelectedTab] = useState("Contacts");

  //invite modal
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [linkedDevices, setLinkedDevices] = useState([]);
  const [inviteLink, setInviteLink] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDevice, setDeleteDevice] = useState(null);
  const [emailAddresses, setEmailAddresses] = useState("");
  const [inviteResults, setInviteResults] = useState([]);
  const [isSending, setIsSending] = useState(false);
  // 使用useState来追踪当前活动按钮

  // DND save settings

  useEffect(() => {
    fetchLinkedDevices();
    generateDeepLink();
  }, []);

  const fetchLinkedDevices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/linked_devices/${user.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        const filteredData = data.filter((device) => device.id !== user.id);
        setLinkedDevices(filteredData);
      } else {
        console.log("Failed to fetch linked devices.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const generateDeepLink = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/generate_deep_link/${user.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        setInviteLink(data.url);
      } else {
        console.log("Failed to generate deep link.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleUnpairDevice = async (deviceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/unpair?id=${deviceId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({ id: deviceId }),
          credentials: "include",
        }
      );

      if (response.ok) {
        setLinkedDevices(
          linkedDevices.filter((device) => device.id !== deviceId)
        );
        toast.success("Device unpaired successfully!");
        fetchLinkedDevices();
      } else {
        console.log("Failed to unpair device.");
        toast.error("Failed to unpair device.");
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred while unpairing the device.");
    }
  };

  const handleDeleteDeviceClick = (device) => {
    setDeleteDevice(device);
    setShowDeleteModal(true);
  };

  const handleDeleteDeviceCancel = () => {
    setShowDeleteModal(false);
    setDeleteDevice(null);
  };

  const handleUnpairClick = () => {
    if (deleteDevice) {
      handleUnpairDevice(deleteDevice.id);
      setShowDeleteModal(false);
      setDeleteDevice(null);
    }
  };

  // const handleTabClick = (tab) => {
  //   setSelectedTab(tab);
  // };

  //invite modal
  const handleInviteModal = () => {
    setShowInviteModal(true);
  };

  const handleSendClick = async (event) => {
    event.preventDefault();
    setIsSending(true);
    setInviteResults([]);

    const emailAddressArray = emailAddresses
      .split(/[,;\n]/)
      .map((email) => email.trim())
      .filter((email) => email !== "");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (const email of emailAddressArray) {
      if (emailRegex.test(email)) {
        const requestBody = {
          deviceID: user.id,
          emailTo: email,
          inviteLink: inviteLink,
        };

        try {
          const response = await fetch(
            `${process.env.REACT_APP_DEVICE_API_URL}/send_invite`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              body: JSON.stringify(requestBody),
              credentials: "include",
            }
          );

          if (response.ok) {
            setInviteResults((prevResults) => [
              ...prevResults,
              { email, success: true, message: "Sent successfully" },
            ]);
          } else {
            setInviteResults((prevResults) => [
              ...prevResults,
              { email, success: false, message: "Failed to send" },
            ]);
          }
        } catch (error) {
          console.log("Error:", error);
          setInviteResults((prevResults) => [
            ...prevResults,
            { email, success: false, message: "Error occurred" },
          ]);
        }
      } else {
        setInviteResults((prevResults) => [
          ...prevResults,
          { email, success: false, message: "Invalid email address" },
        ]);
      }
    }

    setIsSending(false);
  };

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(
      `You are invited to pair the device "${user.name}" with this link : ${inviteLink}`
    );
    toast.success("Invite link copied to clipboard!");
  };

  return (
    <div>
      <div
        className="white_shd full margin_bottom_30"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            marginLeft: "40px",
          }}
        >
          {user.profilePicture ? (
            <img
              className="user-photo"
              src={`data:image/png;base64, ${user.profilePicture}`}
              alt={user.name}
            />
          ) : (
            <img
              className="user-photo"
              src={deviceProfile}
              alt="Default profile"
            />
          )}

          <h2>{user.name}</h2>
        </div>
        <div className="full inner_elements">
          <div className="row">
            <div className="col-md-12">
              <div className="tab_style3">
                <div
                  className="tabbar padding_infor_info"
                  style={{ display: "block" }}
                >
                  <div>
                    <p
                      style={{
                        fontWeight: "700",
                        color: "black",
                        marginLeft: "10px",
                        marginTop: "0px",
                      }}
                    >
                      CareWindow User
                    </p>
                    <p
                      style={{
                        fontWeight: "700",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      S/N: {user.serialNo}
                    </p>
                    {/* {user.deviceSN} */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <nav>
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <div
                          className={`nav-link ${
                            selectedTab === "Contacts" ? "active" : ""
                          }`}
                          id="nav-contacts-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-contacts"
                          aria-selected={selectedTab === "Contacts"}
                          onClick={() => handleTabClick("Contacts")}
                          style={{ width: "140px" }}
                        >
                          Contacts
                        </div>
                        <div
                          className={`nav-link ${
                            selectedTab === "DoNotDisturb" ? "active" : ""
                          }`}
                          id="nav-dnd-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-dnd"
                          aria-selected={selectedTab === "DoNotDisturb"}
                          onClick={() => handleTabClick("DoNotDisturb")}
                          style={{ width: "140px" }}
                        >
                          Do Not Disturb
                        </div>
                        <div
                          className={`nav-link ${
                            selectedTab === "Settings" ? "active" : ""
                          }`}
                          id="nav-settings-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-settings"
                          aria-selected={selectedTab === "Settings"}
                          onClick={() => handleTabClick("Settings")}
                          style={{ width: "140px" }}
                        >
                          Settings
                        </div>
                      </div>
                    </nav> */}

                    <div
                      className="tab-content"
                      id="v-pills-nav-tabContent"
                      style={{
                        flexGrow: "1",
                        paddingTop: "0",
                        paddingLeft: "10px",
                      }}
                    >
                      <div
                        className={`tab-pane fade ${selectedTab === "Contacts" ? "show active" : ""
                          }`}
                        id="nav-contacts"
                        role="tabpanel"
                        aria-labelledby="nav-contacts-tab"
                      >
                        <div style={{ display: "block" }}>
                          <button
                            onClick={handleInviteModal}
                            type="button"
                            className="btn cur-p btn-dark"
                            style={{
                              marginRight: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            Invite Contacts by Email
                          </button>
                          <button
                            onClick={handleCopyInviteLink}
                            type="button"
                            className="btn cur-p btn-outline-dark"
                            style={{ marginBottom: "20px" }}
                          >
                            Copy Invite Link
                          </button>
                        </div>
                        <table className="table table-hover">
                          <caption
                            style={{
                              captionSide: "top",
                              textAlign: "center",
                              marginRight: "160px",
                            }}
                          >
                            <h3>Contacts</h3>
                          </caption>
                          <thead>
                            <tr>
                              <th>Contact Name</th>
                              {/* <th>Role</th>
                              <th>Date</th>
                              <th>Location</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {linkedDevices.map((device) => (
                              <tr key={device.id}>
                                <td>
                                  {device.profilePicture ? (
                                    <img
                                      className="user-photo"
                                      src={`data:image/png;base64, ${device.profilePicture}`}
                                      alt={device.name}
                                    />
                                  ) : (
                                    <img
                                      className="user-photo"
                                      src={deviceProfile}
                                      alt="Default profile"
                                    />
                                  )}

                                  {device.name}
                                </td>

                                {/* <td style={{ verticalAlign: "middle" }}>
                                  Role
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  Date
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  Location
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {/* <PhoneCallIcon
                                    sx={{
                                      fontSize: "1rem", // 调整图标大小
                                      color: "black", // 调整图标颜色
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                  /> */}
                                  {/* <EditIcon
                                    sx={{
                                      fontSize: "1rem", // 调整图标大小
                                      color: "black", // 调整图标颜色
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                  /> */}
                                  <TrashIcon
                                    sx={{
                                      fontSize: "1rem", // 调整图标大小
                                      color: "black", // 调整图标颜色
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteDeviceClick(device)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* <div
                        className={`tab-pane fade ${
                          selectedTab === "DoNotDisturb" ? "show active" : ""
                        }`}
                        id="nav-dnd"
                        role="tabpanel"
                        aria-labelledby="nav-dnd-tab"
                      >
                        <DoNotDisturb user={user} settingsFrom={settingsFrom}/>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          selectedTab === "Settings" ? "show active" : ""
                        }`}
                        id="nav-settings"
                        role="tabpanel"
                        aria-labelledby="nav-settings-tab"
                      >
                        <SettingsTab
                          user={user}
                          onApplyToAllDevices={onApplyToAllDevices}
                          onApplyToThisDevice={onApplyToThisDevice}
                          settingsFrom={settingsFrom}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showInviteModal && (
        <div className="modal" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ width: "400px" }}>
              <div className="modal-header">
                <h1 className="modal-title">Invite Contacts</h1>
                <button
                  className="close-button"
                  onClick={() => setShowInviteModal(false)}
                >
                  &times;
                </button>
              </div>
              <form className="contact-form" onSubmit={handleSendClick}>
                <div className="mb-3">
                  <p>You may enter up to 6 email addresses, one per line.</p>
                  <strong>
                    <label
                      for="ContactsEmailAddress"
                      className="form-label"
                      style={{ marginTop: "20px" }}
                    >
                      Email Addresses
                    </label>
                  </strong>
                  <textarea
                    className="form-control"
                    id="ContactsEmailAddress"
                    rows="8"
                    placeholder={`joe.baker@example.com\nfran.baker@example.com`}
                    value={emailAddresses}
                    onChange={(e) => setEmailAddresses(e.target.value)}
                  ></textarea>
                </div>
                {inviteResults.length > 0 && (
                  <div className="mb-3">
                    <h5>Invite Results:</h5>
                    <ul>
                      {inviteResults.map((result, index) => (
                        <li
                          key={index}
                          className={
                            result.success ? "text-success" : "text-danger"
                          }
                        >
                          {result.email}: {result.message}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn cur-p btn-secondary"
                    onClick={() => setShowInviteModal(false)}
                    disabled={isSending}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn cur-p btn-primary"
                    disabled={isSending}
                  >
                    {isSending ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Delete Device</h1>
                <button
                  type="button"
                  className="close-button"
                  onClick={handleDeleteDeviceCancel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to unenroll{" "}
                  <strong>{deleteDevice?.name}</strong>?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteDeviceCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleUnpairClick}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function DoNotDisturb({ user, policy, settingsFrom }) {
  const [settings, setSettings] = useState([]);
  const [selectedDNDOption, setSelectedDNDOption] = useState("allow");
  const [selectedDays, setSelectedDays] = useState([]);
  const [activeCallsOptionButton, setActiveCallsOptionButton] = useState(1); // 初始为第一个按钮激活
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [isDndEnabled, setIsDndEnabled] = useState(true);

  const [startHour, setStartHour] = useState("12");
  const [startMinute, setStartMinute] = useState("00");
  const [startPeriod, setStartPeriod] = useState("AM");
  const [endHour, setEndHour] = useState("12");
  const [endMinute, setEndMinute] = useState("00");
  const [endPeriod, setEndPeriod] = useState("PM");
  const [policySettingCategoryGroups, setPolicySettingCategoryGroups] =
    useState([]);

  // const [isDndEnabled, setIsDndEnabled] = useState(false);

  const isReadOnly = selectedDNDOption !== "manage";

  const hours = Array.from({ length: 13 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  // const selectMinutes = Array.from({ length: 12 }, (_, i) =>
  //   (i * 5).toString().padStart(2, "0")
  // );
  const periods = ["AM", "PM"];

  useEffect(() => {
    if (settingsFrom === "ManageDevices") {
      fetchDND();
    } else if (settingsFrom === "PolicyGroups") {
      fetchPolicyGroupDND();
    }
  }, []);

  const fetchDND = async () => {
    try {
      const response = await fetch(
        `http://localhost:5196/profile/get_settings?id=${user.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSettings(data);
        const doNotDisturbSetting = data.find(
          (setting) => setting.name === "Do Not Disturb"
        );
        if (doNotDisturbSetting) {
          updateDNDState(doNotDisturbSetting);
        }
      } else {
        console.log("Failed to fetch Do Not Disturb settings.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchPolicyGroupDND = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICYSETTING_API_URL}/get_policy_settings?id=${policy.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSettings(data.polictSettings);
        setPolicySettingCategoryGroups(data.policySettingCategoryGroups);
        const doNotDisturbSetting = data.polictSettings.find(
          (setting) => setting.name === "Enable Do Not Disturb"
        );

        if (doNotDisturbSetting) {
          updateDNDState(doNotDisturbSetting);
        }

        updateSelectedDNDOption(data.policySettingCategoryGroups);
      } else {
        console.log("Failed to fetch Do Not Disturb settings.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const updateSelectedDNDOption = (categoryGroups) => {
    const dndCategory = categoryGroups.find(
      (category) => category.categoryName === "Do Not Disturb"
    );

    if (dndCategory) {
      setSelectedDNDOption(dndCategory.locked ? "manage" : "allow");
    }
  };

  const handleCategoryLockChange = async (locked) => {
    const category = policySettingCategoryGroups.find(
      (category) => category.categoryName === "Do Not Disturb"
    );

    if (category) {
      category.locked = locked;
      setSelectedDNDOption(locked ? "manage" : "allow");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting_lock?PolicyId=${policy.id}&Id=${category.id}&locked=${locked}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update category lock");
        }
      } catch (error) {
        console.log("Error:", error);
        toast.error("Failed to update category lock");
      }
    }
  };

  const updateDNDState = (doNotDisturbSetting) => {
    let parsedValue = doNotDisturbSetting.value
      ? JSON.parse(doNotDisturbSetting.value)
      : null;

    if (parsedValue === null) {
      const defaultStartTime = new Date();
      defaultStartTime.setHours(0, 0, 0, 0);

      const defaultEndTime = new Date();
      defaultEndTime.setHours(12, 0, 0, 0);

      parsedValue = {
        start: defaultStartTime.toISOString(),
        end: defaultEndTime.toISOString(),
        selectedDay: [],
        isDndEnabled: false,
        CallHandling: "BlockCalls",
      };
    }

    const { start, end, selectedDay, isDndEnabled, CallHandling } = parsedValue;

    setIsDndEnabled(isDndEnabled || false);
    const startDateTime = dayjs(start);
    const endDateTime = dayjs(end);

    setStartHour(startDateTime.format("hh"));
    const parsedStartMinute = parseInt(startDateTime.format("mm"), 10);
    setStartMinute(parsedStartMinute);
    setStartPeriod(startDateTime.format("A"));

    setEndHour(endDateTime.format("hh"));
    const parsedEndMinute = parseInt(endDateTime.format("mm"), 10);
    setEndMinute(parsedEndMinute);
    setEndPeriod(endDateTime.format("A"));

    setStartMinute(parsedStartMinute.toString().padStart(2, "0"));
    setEndMinute(parsedEndMinute.toString().padStart(2, "0"));

    const selectedDaysOfWeek = selectedDay.map(
      (dayNumber) => daysOfWeek[dayNumber - 1]
    );
    setSelectedDays(selectedDaysOfWeek);

    const updatedCheckedDays = {};
    daysOfWeek.forEach((day, index) => {
      updatedCheckedDays[day] = selectedDay.includes(index + 1);
    });
    setCheckedDays(updatedCheckedDays);

    setActiveCallsOptionButton(CallHandling === "AllowCalls" ? 2 : 1);
  };

  const handleSaveDND = async () => {
    try {
      const startDate = new Date();
      startDate.setHours(
        startPeriod === "AM" ? parseInt(startHour) : parseInt(startHour) + 12
      );
      const startMinuteValue = parseInt(startMinute, 10);
      startDate.setMinutes(parseInt(startMinuteValue));

      const endDate = new Date();
      endDate.setHours(
        endPeriod === "AM" ? parseInt(endHour) : parseInt(endHour) + 12
      );
      const endMinuteValue = parseInt(endMinute, 10);
      endDate.setMinutes(endMinuteValue);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error("Invalid start or end time");
      }

      const selectedDayNumbers = Object.keys(checkedDays)
        .filter((day) => checkedDays[day])
        .map((day) => daysOfWeek.indexOf(day) + 1);

      const CallHandling =
        activeCallsOptionButton === 2 ? "AllowCalls" : "BlockCalls";

      let value;
      if (settingsFrom === "ManageDevices") {
        value = {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          selectedDay: selectedDayNumbers,
          isDndEnabled: isDndEnabled,
          CallHandling: CallHandling,
        };
      } else if (settingsFrom === "PolicyGroups") {
        value = {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          selectedDay: selectedDayNumbers,
          isDndEnabled: isDndEnabled,
          CallHandling: CallHandling,
        };
      }

      const updatedSettings = settings
        .map((setting) => {
          if (
            setting.name === "Enable Do Not Disturb" ||
            setting.name === "Do Not Disturb"
          ) {
            return {
              ...setting,
              value: JSON.stringify(value),
            };
          }
          return setting;
        })
        .filter(
          (setting) =>
            setting.name === "Enable Do Not Disturb" ||
            setting.name === "Do Not Disturb"
        );

      console.log("Updated Settings:", updatedSettings);

      if (settingsFrom === "ManageDevices") {
        for (const setting of updatedSettings) {
          const response = await fetch(
            `http://localhost:5196/profile/update_setting`,
            {
              method: "POST",

              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              body: JSON.stringify(setting),
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update setting");
          }
        }
        fetchDND();
      } else if (settingsFrom === "PolicyGroups") {
        for (const setting of updatedSettings) {
          const response = await fetch(
            `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting?PolicyId=${policy.id}&Id=${setting.id}&value=${setting.value}`,
            {
              method: "POST",

              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              credentials: "include",
            }
          );

          if (response.ok) {
            toast.success("Do Not Disturb settings updated successfully!");
            fetchPolicyGroupDND();
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("Failed to update Do Not Disturb settings");
    }
  };

  const handleStartHourChange = (event) => setStartHour(event.target.value);
  const handleStartMinuteChange = (event) => setStartMinute(event.target.value);
  const handleStartPeriodChange = (event) => setStartPeriod(event.target.value);

  const handleEndHourChange = (event) => setEndHour(event.target.value);
  const handleEndMinuteChange = (event) => setEndMinute(event.target.value);
  const handleEndPeriodChange = (event) => setEndPeriod(event.target.value);

  const [checkedDays, setCheckedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedDays((prevCheckedDays) => ({
      ...prevCheckedDays,
      [id]: checked,
    }));
  };

  // const handleDNDOptionChange = (option) => {
  //   setSelectedDNDOption(option);
  // };

  const handleCallsOptionButtonClick = (buttonNumber) => {
    if (activeCallsOptionButton === buttonNumber) {
      setActiveCallsOptionButton(null);
    } else {
      setActiveCallsOptionButton(buttonNumber);
    }
  };

  // const toggleDay = (day) => {
  //   if (selectedDays.includes(day)) {
  //     setSelectedDays(
  //       selectedDays.filter((selectedDay) => selectedDay !== day)
  //     );
  //   } else {
  //     setSelectedDays([...selectedDays, day]);
  //   }
  // };

  return (
    <div className="doNotDisturbContainer">
      <div
        style={{
          width: "500px",
          display: "flex",
          paddingLeft: "15px",
          marginBottom: "20px",
        }}
      >
        <h4>Do Not Disturb</h4>
        <div className="dropdown">
          <button
            className="btn lockerBtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedDNDOption === "allow" ? (
              <img src={unlockedIcon} style={{ height: "20px" }} />
            ) : (
              <img src={lockedIcon} style={{ height: "20px" }} />
            )}
          </button>
          <ul className="dropdown-menu dropdown-item-ul ">
            <li onClick={() => handleCategoryLockChange(false)}>
              <span>
                <LockOpenRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Allow device user and family to change these settings
              {selectedDNDOption === "allow" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
            <li onClick={() => handleCategoryLockChange(true)}>
              <span>
                <LockRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0px 5px",
                  }}
                />
              </span>
              Organisation manages these settings
              {selectedDNDOption === "manage" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="container text-left">
        <div className="row mb-5">
          <div className="col col-sm-3 align-items-center d-flex">
            <p>Enable Do Not Disturb</p>
          </div>
          <div className="row col-sm-9 align-items-center d-flex">
            <Switch
              checked={isDndEnabled}
              onChange={(e) => setIsDndEnabled(e.target.checked)}
              disabled={isReadOnly}
            />
          </div>
        </div>
        {isDndEnabled && (
          <>
            <div className="row mb-5">
              <div className="col col-sm-3 align-items-center d-flex">
                <p>Block or allow calls?</p>
              </div>
              <div className="row col-sm-9 align-items-center d-flex">
                <div className="form-check mr-4 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={activeCallsOptionButton === 1}
                    onChange={() => handleCallsOptionButtonClick(1)}
                    disabled={isReadOnly}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Block calls
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={activeCallsOptionButton === 2}
                    onChange={() => handleCallsOptionButtonClick(2)}
                    disabled={isReadOnly}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Allow calls
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-sm-3 align-items-center d-flex">
                Start time
              </div>
              <div className="col col-sm-9 align-items-cente">
                <select
                  className="dndTimeSelector"
                  value={startHour}
                  onChange={handleStartHourChange}
                  disabled={isReadOnly}
                >
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <strong>:</strong>
                <select
                  style={{ marginLeft: "1em" }}
                  className="dndTimeSelector"
                  value={startMinute}
                  onChange={handleStartMinuteChange}
                  disabled={isReadOnly}
                >
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
                <select
                  style={{ marginLeft: "1em" }}
                  className="dndTimeSelector"
                  value={startPeriod}
                  onChange={handleStartPeriodChange}
                  disabled={isReadOnly}
                >
                  {periods.map((period) => (
                    <option key={period} value={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col col-sm-3 align-items-center d-flex">
                <p>End time</p>
              </div>
              <div className="col col-sm-9 align-items-center">
                <select
                  className="dndTimeSelector"
                  value={endHour}
                  onChange={handleEndHourChange}
                  disabled={isReadOnly}
                >
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <strong>:</strong>
                <select
                  style={{ marginLeft: "1em" }}
                  className="dndTimeSelector"
                  value={endMinute}
                  onChange={handleEndMinuteChange}
                  disabled={isReadOnly}
                >
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
                <select
                  style={{ marginLeft: "1em" }}
                  className="dndTimeSelector"
                  value={endPeriod}
                  onChange={handleEndPeriodChange}
                  disabled={isReadOnly}
                >
                  {periods.map((period) => (
                    <option key={period} value={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col col-sm-3 align-items-center">
                <p>Active days</p>
              </div>
              <div className="col col-sm-9 align-items-center">
                {Object.keys(checkedDays).map((day) => (
                  <div key={day} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={day}
                      value={day}
                      checked={checkedDays[day]}
                      onChange={handleCheckboxChange}
                      disabled={isReadOnly}
                    />
                    <label className="form-check-label mr-3" htmlFor={day}>
                      {day.slice(0, 3)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* <div>
                          {settingsFrom === "ManageDevices" ? (
                            <button
                              // onClick={handleSaveSettings}
                              className="btn cur-p btn-dark"
                            >
                              Apply
                            </button>
                          ) : (
                            <>
                              <button
                                onClick={onApplyToAllDevices}
                                className="btn cur-p btn-outline-dark"
                              >
                                Apply to All Devices
                              </button>
                              <button
                                onClick={onApplyToThisDevice}
                                className="btn cur-p btn-dark"
                                style={{ marginLeft: "20px" }}
                              >
                                Apply to This Device
                              </button>
                            </>
                          )}
                        </div> */}
      </div>

      <div className="button">
        {/* <button
                          onClick={onApplyToAllDevices}
                          className="btn cur-p btn-outline-dark"
                        >
                          Apply to All Devices
                        </button> */}
        <button
          onClick={handleSaveDND}
          className="btn cur-p btn-dark"
          style={{ marginLeft: "20px" }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

function SettingsTab({
  user,
  policy,
  onApplyToAllDevices,
  onApplyToThisDevice,
  settingsFrom,
}) {
  const [settings, setSettings] = useState([]);
  const [autoAnswer, setAutoAnswer] = useState(false);
  //const [autoAnswerDuringDND, setAutoAnswerDuringDND] = useState(false);
  const [autoAnswerAudioAlert, setAutoAnswerAudioAlert] = useState(false);
  const [autoAnswerDelay, setAutoAnswerDelay] = useState(10);
  const [doNotDisturb, setDoNotDisturb] = useState(false);

  //const [setTimeZoneAutomatically, setSetTimeZoneAutomatically] =
  // useState(true);
  // const [region, setRegion] = useState("Australia");
  // const [timeZone, setTimeZone] = useState("Adelaide (UTC +9:30)");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [outgingCalls, setOutgoingCalls] = useState(false);
  const [policySettingCategoryGroups, setPolicySettingCategoryGroups] =
    useState([]);

  useEffect(() => {
    if (settingsFrom === "ManageDevices") {
      fetchSettings();
    } else if (settingsFrom === "PolicyGroups") {
      fetchPolicyGroupSettings();
    }

    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const tZ = "Australia/Adelaide"; // 替换为你想要显示的时区

  // const zonedDateTime = toZonedTime(currentDateTime, tZ);

  // const formatPattern = "yyyy-MM-dd HH:mm:ss"; // 替换为你想要的日期时间格式

  // const formattedDateTime = format(zonedDateTime, formatPattern, { tZ });

  const fetchSettings = async () => {
    try {
      const response = await fetch(
        `http://localhost:5196/profile/get_settings?id=${user.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSettings(data);
        updateSettingsState(data);
      } else {
        console.log("Failed to fetch settings.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchPolicyGroupSettings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICYSETTING_API_URL}/get_policy_settings?id=${policy.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPolicySettingCategoryGroups(data.policySettingCategoryGroups);
        setSettings(data.polictSettings);
        updateSettingsState(data.polictSettings);
      } else {
        console.log("Failed to fetch settings.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const updateSettingsState = (settingsData) => {
    settingsData.forEach((setting) => {
      switch (setting.name) {
        case "Auto Answer":
          setAutoAnswer(setting.value === "true");
          break;
        case "Play Sound":
        case "Auto Answer audio alert":
          setAutoAnswerAudioAlert(setting.value === "true");
          break;
        case "Auto Answer delay":
          setAutoAnswerDelay(
            setting.value !== null ? parseInt(setting.value) : 10
          );
          break;
        case "Allow device to make outgoing calls":
        case "Outgoing Calls":
          setOutgoingCalls(setting.value === "true");
          break;
        case "Enable Do Not Disturn":
        case "Do Not Disturb":
          setDoNotDisturb(setting.value !== "");
          break;
        default:
          break;
      }
    });
  };

  const handleSaveSettings = async () => {
    const updatedSettings = settings.filter((setting) => {
      switch (setting.name) {
        case "Auto Answer":
          setting.value = autoAnswer.toString();
          return true;
        case "Play Sound":
        case "Auto Answer audio alert":
          setting.value = autoAnswerAudioAlert.toString();
          return true;
        case "Auto Answer delay":
          setting.value = autoAnswerDelay.toString();
          return true;
        case "Allow device to make outgoing calls":
        case "Outgoing Calls":
          setting.value = outgingCalls.toString();
          return true;
        default:
          return false;
      }
    });

    try {
      if (settingsFrom === "ManageDevices") {
        for (const setting of updatedSettings) {
          const response = await fetch(
            `http://localhost:5196/profile/update_setting`,
            {
              method: "POST",

              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
              },
              body: JSON.stringify(setting),
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update setting");
          }
        }
      } else if (settingsFrom === "PolicyGroups") {
        for (const setting of updatedSettings) {
          const response = await fetch(
            `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting?PolicyId=${policy.id}&Id=${setting.id}&value=${setting.value}`,
            {
              method: "POST",

              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update setting");
          }
        }

        for (const category of policySettingCategoryGroups) {
          const response = await fetch(
            `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting_lock?PolicyId=${policy.id}&Id=${category.id}&locked=${category.locked}`,
            {
              method: "POST",

              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update category lock");
          }
        }
      }

      toast.success("Update successfully!");
      fetchPolicyGroupSettings();
    } catch (error) {
      console.log("Error:", error);
      toast.error("Failed to update settings");
    }
  };
  //settings
  const handleAutoAnswerChange = (event) => {
    setAutoAnswer(event.target.checked);
  };

  // const handleAutoAnswerDuringDNDChange = (event) => {
  //   setAutoAnswerDuringDND(event.target.checked);
  // };

  const handleAutoAnswerAudioAlertChange = (event) => {
    setAutoAnswerAudioAlert(event.target.checked);
  };

  const handleAutoAnswerDelayChange = (event) => {
    setAutoAnswerDelay(event.target.value);
  };

  // const handleDoNotDisturbChange = (event) => {
  //   setDoNotDisturb(event.target.checked);
  // };

  // time
  // const handleSetTimeZoneAutomaticallyChange = (event) => {
  //   setSetTimeZoneAutomatically(event.target.checked);
  // };

  // const handleRegionChange = (event) => {
  //   setRegion(event.target.value);
  // };

  // const handleTimeZoneChange = (event) => {
  //   setTimeZone(event.target.value);
  // };

  const handleOutgoingCalls = (event) => {
    setOutgoingCalls(event.target.checked);
  };

  // dropdown icon - call answering

  const getCategoryLockedStatus = (categoryName) => {
    const category = policySettingCategoryGroups.find(
      (category) => category.categoryName === categoryName
    );
    return category ? category.locked : false;
  };

  const handleCategoryLockChange = async (categoryName, locked) => {
    const category = policySettingCategoryGroups.find(
      (category) => category.categoryName === categoryName
    );
    if (category) {
      category.locked = locked;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting_lock?PolicyId=${policy.id}&Id=${category.id}&locked=${locked}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update category lock");
        }
      } catch (error) {
        console.log("Error:", error);
        toast.error("Failed to update category lock");
      }
    }
  };

  // dropdown icon - outgoing call
  // const [selectedOutgoingCallOption, setSelectedOutgoingCallOption] =
  //   useState("allow"); // 初始选中为 'allow'

  // const handleOutgoingCallOptionChange = (option) => {
  //   setSelectedOutgoingCallOption(option);
  // };

  // dropdown icon - Date and Time
  // const [selectedDateandTimeOption, setSelectedDateandTimeOption] =
  //   useState("allow"); // 初始选中为 'allow'

  // const handleDateandTimeOptionChange = (option) => {
  //   setSelectedDateandTimeOption(option);
  // };

  return (
    <div style={{ marginLeft: "40px", marginBottom: "30px" }}>
      <div style={{ display: "flex" }}>
        <h4>Call Answering</h4>
        <div className="dropdown">
          <button
            className="btn lockerBtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {getCategoryLockedStatus("Incoming Calls") ? (
              <img src={lockedIcon} style={{ height: "20px" }} />
            ) : (
              <img src={unlockedIcon} style={{ height: "20px" }} />
            )}
          </button>

          <ul className="dropdown-menu dropdown-item-ul">
            <li
              onClick={() => handleCategoryLockChange("Incoming Calls", false)}
            >
              <span>
                <LockOpenRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Allow device user and family to change these settings
              {!getCategoryLockedStatus("Incoming Calls") && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
            <li
              onClick={() => handleCategoryLockChange("Incoming Calls", true)}
            >
              <span>
                <LockRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0px 5px",
                  }}
                />
              </span>
              Organisation manages these settings
              {getCategoryLockedStatus("Incoming Calls") && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <label style={{ marginRight: "194px", marginBottom: "30px" }}>
          Auto-answer incoming calls
        </label>
        <Switch
          checked={autoAnswer}
          onChange={handleAutoAnswerChange}
          color="primary"
          disabled={!getCategoryLockedStatus("Incoming Calls")}
        />
      </div>
      <div>
        <label style={{ marginBottom: "30px" }}>
          Auto-answer delay
          <input
            type="number"
            value={autoAnswerDelay}
            onChange={handleAutoAnswerDelayChange}
            style={{ marginLeft: "265px", width: "50px", height: "40px" }}
            disabled={!getCategoryLockedStatus("Incoming Calls")}
          />
          s
        </label>
      </div>
      <div>
        <label style={{ marginRight: "190px", marginBottom: "30px" }}>
          Play sound for incoming calls
        </label>
        <Switch
          checked={autoAnswerAudioAlert}
          onChange={handleAutoAnswerAudioAlertChange}
          color="primary"
          disabled={!getCategoryLockedStatus("Incoming Calls")}
        />
      </div>

      <div className="manageDevicesTitle">
        <h4>Outgoing Calls</h4>
        <div className="dropdown">
          <button
            className="btn lockerBtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {getCategoryLockedStatus("Outgoing Calls") ? (
              <img src={lockedIcon} style={{ height: "20px" }} />
            ) : (
              <img src={unlockedIcon} style={{ height: "20px" }} />
            )}
          </button>

          <ul className="dropdown-menu dropdown-item-ul">
            <li
              onClick={() => handleCategoryLockChange("Outgoing Calls", false)}
            >
              <span>
                <LockOpenRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Allow device user and family to change these settings
              {!getCategoryLockedStatus("Outgoing Calls") && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
            <li
              onClick={() => handleCategoryLockChange("Outgoing Calls", true)}
            >
              <span>
                <LockRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Organisation manages these settings
              {getCategoryLockedStatus("Outgoing Calls") && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <label style={{ marginRight: "140px", marginBottom: "30px" }}>
          Allow device to make outgoing calls
        </label>
        <Switch
          checked={outgingCalls}
          onChange={handleOutgoingCalls}
          color="primary"
          disabled={!getCategoryLockedStatus("Outgoing Calls")}
        />
      </div>

      {/* <div className="manageDevicesTitle">
        <h4>Date and Time</h4>
        <div className="dropdown">
          <button
            className="btn lockerBtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedDateandTimeOption === "allow" ? (
              <img src={unlockedIcon} style={{ height: "20px" }} />
            ) : (
              <img src={lockedIcon} style={{ height: "20px" }} />
            )}
          </button>

          <ul className="dropdown-menu dropdown-item-ul">
            <li onClick={() => handleDateandTimeOptionChange("allow")}>
              <span>
                <LockOpenRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Allow device user and family to change these settings
              {selectedDateandTimeOption === "allow" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
            <li onClick={() => handleDateandTimeOptionChange("manage")}>
              <span>
                <LockRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Organisation manages these settings
              {selectedDateandTimeOption === "manage" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div style={{ display: 'flex', marginTop: '40px' }}>
        <p style={{ marginRight: '250px', marginBottom: '30px' }}>Current time on device</p>
        <p>{formattedDateTime}</p>
      </div>
      <div>
        <label style={{ marginRight: "200px", marginBottom: "30px" }}>
          Set Time Zone Automatically
        </label>
        <Switch
          checked={setTimeZoneAutomatically}
          onChange={handleSetTimeZoneAutomaticallyChange}
          color="primary"
          disabled={selectedDateandTimeOption === "manage"} // 只有当选项为'manage'时才禁用Switch
        />
      </div>
      <div>
        <label>
          Region
          <select
            value={region}
            onChange={handleRegionChange}
            style={{
              marginLeft: "360px",
              marginBottom: "30px",
              width: "200px",
              height: "40px",
            }}
            disabled={selectedDateandTimeOption === "manage"}
          >
            <option value="Australia">Australia</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Time Zone
          <select value={timeZone} onChange={handleTimeZoneChange} style={{ marginLeft: '337px', marginBottom: '30px', width: '200px', height: '40px' }} disabled={selectedDateandTimeOption === 'manage'}>
            <option value="Adelaide (UTC +9:30)" >Adelaide (UTC +9:30)</option>
            
          </select>
        </label>
      </div> */}

      <div>
        {settingsFrom === "ManageDevices" ? (
          <button onClick={handleSaveSettings} className="btn cur-p btn-dark">
            Save
          </button>
        ) : (
          <>
            {/* <button onClick={onApplyToAllDevices} className='btn cur-p btn-outline-dark'>Apply to All Devices</button>
            <button onClick={onApplyToThisDevice} className='btn cur-p btn-dark' style={{ marginLeft: "20px" }}>Apply to This Device</button> */}
            <button onClick={handleSaveSettings} className="btn cur-p btn-dark">
              Apply
            </button>
          </>
        )}
      </div>
    </div>
  );
}
function PairingTab({ policy, settingsFrom }) {
  const [settings, setSettings] = useState([]);
  const [showQRCodeAndInviteButton, setShowQRCodeAndInviteButton] =
    useState(false);
  const [showUnpairButton, setShowUnpairButton] = useState(false);
  const [policySettingCategoryGroups, setPolicySettingCategoryGroups] =
    useState([]);
  const [selectedPairingOption, setSelectedPairingOption] = useState("allow");

  // const getCategoryLockedStatus = (categoryName) => {
  //   const category = policySettingCategoryGroups.find(
  //     (category) => category.categoryName === categoryName
  //   );
  //   return category ? category.locked : false;
  // };

  const isReadOnly = selectedPairingOption !== "manage";

  useEffect(() => {
    fetchPairingSettings();
  }, []);

  const fetchPairingSettings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICYSETTING_API_URL}/get_policy_settings?id=${policy.id}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSettings(data.polictSettings);
        setPolicySettingCategoryGroups(data.policySettingCategoryGroups);
        updatePairingState(data.polictSettings);
        updateSelectedPairingOption(data.policySettingCategoryGroups);
      } else {
        console.log("Failed to fetch Pairing settings.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const updatePairingState = (pairingSettings) => {
    pairingSettings.forEach((setting) => {
      switch (setting.name) {
        case "Show QR Code and Invite by Email button on device":
          setShowQRCodeAndInviteButton(setting.value === "true");
          break;
        case "Show Unpair button on device":
          setShowUnpairButton(setting.value === "true");
          break;
        default:
          break;
      }
    });
  };

  const updateSelectedPairingOption = (categoryGroups) => {
    const pairingCategory = categoryGroups.find(
      (category) => category.categoryName === "Pairing"
    );
    if (pairingCategory) {
      setSelectedPairingOption(pairingCategory.locked ? "manage" : "allow");
    }
  };

  const handleSavePairing = async () => {
    const updatedSettings = settings
      .filter((setting) =>
        [
          "Show QR Code and Invite by Email button on device",
          "Show Unpair button on device",
        ].includes(setting.name)
      )
      .map((setting) => {
        switch (setting.name) {
          case "Show QR Code and Invite by Email button on device":
            return { ...setting, value: showQRCodeAndInviteButton.toString() };
          case "Show Unpair button on device":
            return { ...setting, value: showUnpairButton.toString() };
          default:
            return setting;
        }
      });

    try {
      for (const setting of updatedSettings) {
        const response = await fetch(
          `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting?PolicyId=${policy.id}&Id=${setting.id}&value=${setting.value}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update setting");
        }
      }

      toast.success("Pairing settings updated successfully!");
      fetchPairingSettings();
    } catch (error) {
      console.log("Error:", error);
      toast.error("Failed to update Pairing settings");
    }
  };

  const handleCategoryLockChange = async (locked) => {
    const category = policySettingCategoryGroups.find(
      (category) => category.categoryName === "Pairing"
    );
    if (category) {
      category.locked = locked;
      setSelectedPairingOption(locked ? "manage" : "allow");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_POLICYSETTING_API_URL}/update_policy_setting_lock?PolicyId=${policy.id}&Id=${category.id}&locked=${locked}`,
          {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update category lock");
        }
      } catch (error) {
        console.log("Error:", error);
        toast.error("Failed to update category lock");
      }
    }
  };

  return (
    <div style={{ marginLeft: "40px", marginBottom: "30px" }}>
      <div style={{ display: "flex" }}>
        <h4>Pairing</h4>
        <div className="dropdown">
          <button
            className="btn lockerBtn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedPairingOption === "allow" ? (
              <img src={unlockedIcon} style={{ height: "20px" }} />
            ) : (
              <img src={lockedIcon} style={{ height: "20px" }} />
            )}
          </button>
          <ul className="dropdown-menu dropdown-item-ul">
            <li onClick={() => handleCategoryLockChange(false)}>
              <span>
                <LockOpenRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0 5px",
                  }}
                />
              </span>
              Allow device user and family to change these settings
              {selectedPairingOption === "allow" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
            <li onClick={() => handleCategoryLockChange(true)}>
              <span>
                <LockRoundedIcon
                  sx={{
                    width: "20px",
                    margin: "0px 5px",
                  }}
                />
              </span>
              Organisation manages these settings
              {selectedPairingOption === "manage" && (
                <span style={{ float: "right", marginRight: "1em" }}>
                  <DoneRoundedIcon />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div>
        <label style={{ marginRight: "194px", marginBottom: "30px" }}>
          Show QR code and Invite by Email button on device
        </label>
        <Switch
          checked={showQRCodeAndInviteButton}
          onChange={(e) => setShowQRCodeAndInviteButton(e.target.checked)}
          color="primary"
          disabled={isReadOnly}
        />
      </div>
      <div>
        <label style={{ marginRight: "190px", marginBottom: "30px" }}>
          Show Unpair button on device
        </label>
        <Switch
          checked={showUnpairButton}
          onChange={(e) => setShowUnpairButton(e.target.checked)}
          color="primary"
          disabled={isReadOnly}
        />
      </div>
      <div>
        <button onClick={handleSavePairing} className="btn cur-p btn-dark">
          Apply
        </button>
      </div>
    </div>
  );
}

function Announcements({ resetFlag, org }) {
  const [groups, setGroups] = useState();
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAnnouncementId, setDeleteAnnouncementId] = useState(null);

  useEffect(() => {
    setSelectedAnnouncement(null);
    setShowModal(false);
    setShowDeleteModal(false);
    setDeleteAnnouncementId(null);
  }, [resetFlag]);

  useEffect(() => {
    fetchPolicyGroups();
    fetchAnnouncements();
  }, [org.id]);

  const fetchPolicyGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_POLICY_API_URL}/policy_by_org?orgid=${org.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filterPolicys = data.filter(
          (policy) => policy.status === "Active"
        );
        setGroups(filterPolicys);
      } else {
        console.log("Failed to fetch policy groups.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchAnnouncements = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANNOUNCEMENT_API_URL}/get_announcement_by_org?id=${org.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAnnouncements(data);
      } else {
        console.log("Failed to fetch announcements.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleAddClick = () => {
    setSelectedAnnouncement(null);
    setShowModal(true);
  };

  const handleAnnouncementSend = async (updatedAnnouncement) => {
    try {
      await fetchAnnouncements();
      setShowModal(false);
      toast.success(selectedAnnouncement ? "Announcement updated successfully!" : "Announcement created successfully!");
    } catch (error) {
      console.log("Error:", error);
      toast.error("Failed to refresh announcements. Please try again.");
    }
  };

  const handleDeleteAnnouncementClick = (deleteAnnouncementId) => {
    if (deleteAnnouncementId) {
      setDeleteAnnouncementId(deleteAnnouncementId);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteAnnouncementCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteAnnouncementConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANNOUNCEMENT_API_URL}/delete_announcement?Id=${deleteAnnouncementId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: 'include',
        }
      );

      if (response.ok) {
        setAnnouncements(
          announcements.filter(
            (announcement) => announcement.id !== deleteAnnouncementId
          )
        );
        setShowDeleteModal(false);
        toast.success("Announcement deleted !");
        fetchAnnouncements();
      } else {
        toast.error('Failed to delete announcement. Please try again');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleClickRow = (selectedAnnouncementId) => {
    const announcement = announcements.find(
      (a) => a.id === selectedAnnouncementId
    );
    const policyGroupIds = announcement.group ? announcement.group.map(g => g.policyGroupId) : [];
    setSelectedAnnouncement({ ...announcement, policyGroupIds });
    setShowModal(true);
  };

  function parseISOString(isoString) {
    const parts = isoString.match(/\d+/g);
    return new Date(Date.UTC(
      parseInt(parts[0], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[2], 10),
      parseInt(parts[3], 10),
      parseInt(parts[4], 10),
      parseInt(parts[5], 10)
    ));
  }

  function formatDateToReadable(date) {
    return date.toLocaleString('en-AU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }

  const formatDate = (isoString) => {
    const date = parseISOString(isoString);
    return formatDateToReadable(date);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
  };

  const getRecipientText = (announcement) => {
    switch (announcement.sentTo) {
      case 'all_policy_group':
        return 'All groups';
      case 'policy_group':
        const groupNames = announcement.group.map(g => g.name).join(', ');
        return `Group(s): ${truncateText(groupNames, 30)}`;
      case 'device':
        const deviceNames = announcement.devices.map(d => d.name).join(', ');
        return `Device(s): ${truncateText(deviceNames, 30)}`;
      default:
        return announcement.sentTo;
    }
  };

  return (
    <>
      <div className="midde_cont">
        <div
          className="container-fluid"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <div className="row column_title">
            <div className="col-md-12">
              <div className="page_title">
                <h2>Announcements</h2>
              </div>
            </div>
          </div>
          {showModal ? (
            <EditAnnouncement
              org={org}
              onBackClick={() => {
                setShowModal(false);
                fetchAnnouncements();
              }}
              onSend={handleAnnouncementSend}
              selectedAnnouncement={selectedAnnouncement}
              groups={groups}
            />
          ) : (
            <>
              <div className="actions col-md-12">
                <button
                  className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                  onClick={handleAddClick}
                >
                  <b>New Announcement +</b>
                </button>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-wrap">
                    <table className="table table-responsive-xl table-hover">
                      <thead>
                        <tr key="header">
                          {/* <th>Subject</th> */}
                          <th>Body</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Recipient</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {announcements?.map((announcement) => (
                          <tr
                            key={announcement.id}
                            onClick={() => handleClickRow(announcement.id)}
                          >
                            {/* <td>{announcement.subject}</td> */}
                            <td>{truncateText(announcement.body, 30)}</td>
                            <td>{formatDate(announcement.startTime)}</td>
                            <td>{formatDate(announcement.endTime)}</td>
                            <td>{getRecipientText(announcement)}</td>
                            <td>
                              <TrashIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteAnnouncementClick(
                                    announcement.id
                                  );
                                }}
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {showDeleteModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Announcement</h5>
                <button
                  type="button"
                  className="close-button"
                  onClick={handleDeleteAnnouncementCancel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete{" "}
                  <strong>
                    {
                      announcements.find(
                        (announcement) =>
                          announcement.id === deleteAnnouncementId
                      )?.subject
                    }
                  </strong>
                  ?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteAnnouncementCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteAnnouncementConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}



function EditAnnouncement({
  onBackClick,
  onSend,
  selectedAnnouncement,
  groups,
  org,
}) {
  const getCurrentDate = () => new Date();
  const getDefaultEndDate = () => new Date(getCurrentDate().getTime() + 24 * 60 * 60 * 1000);

  const newAnnouncement = {
    id: crypto.randomUUID(),
    organizationId: org.id,
    subject: "",
    body: "",
    sentTo: "",
    startTime: getCurrentDate(),
    endTime: getDefaultEndDate(),
    policyGroupIds: [],
    devices: [],
  };

  const [announcement, setAnnouncement] = useState(() => {
    if (selectedAnnouncement) {
      return {
        ...selectedAnnouncement,
        startTime: parseISOString(selectedAnnouncement.startTime),
        endTime: selectedAnnouncement.endTime ? parseISOString(selectedAnnouncement.endTime) : null,
        policyGroupIds: selectedAnnouncement.policyGroupIds || [],
      };
    }
    return newAnnouncement;
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [itemPicker, setItemPicker] = useState(false);
  const [items, setItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [sendTo, setSendTo] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [subjectCharCount, setSubjectCharCount] = useState(0);
  const [bodyCharCount, setBodyCharCount] = useState(0);
  const [displayTarget, setDisplayTarget] = useState([]);
  const [timeError, setTimeError] = useState('');
  const [error, setError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [bodyError, setBodyError] = useState('');

  useEffect(() => {
    if (selectedAnnouncement) {
      setSendTo(selectedAnnouncement.sentTo);
      if (selectedAnnouncement.sentTo === "device") {
        const deviceIds = selectedAnnouncement.devices.map(device => device.deviceId);
        setSelectedItems(deviceIds);
        setDisplayItems(selectedAnnouncement.devices.map(device => device.name));
        setDisplayTarget("Devices");
      } else if (selectedAnnouncement.sentTo === "policy_group") {
        const groupIds = selectedAnnouncement.group.map(group => group.policyGroupId);
        setSelectedItems(groupIds);
        setDisplayItems(selectedAnnouncement.group.map(group => group.name));
        setDisplayTarget("Groups");
      } else if (selectedAnnouncement.sentTo === "all_policy_group") {
        setDisplayTarget("All Groups");
      }
    }
  }, [selectedAnnouncement]);



  useEffect(() => {
    const fetchItems = async () => {
      try {
        let response;
        if (sendTo === "device") {
          response = await fetch(
            `${process.env.REACT_APP_DEVICE_API_URL}/get_org_devices?OrgCode=${org.code}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                'portalscope': 'b3NMWOVzfdRUjrW',
                'validatereq': generateRandomString(30)
              },
              credentials: "include",
            }
          );
        } else if (sendTo === "policy_group") {
          setItems(groups);
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setItems(data);
        } else {
          console.log(`Failed to fetch ${sendTo}s.`);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    if (itemPicker) {
      fetchItems();
    }
  }, [itemPicker, sendTo]);

  useEffect(() => {
    if (selectedAnnouncement) {
      setSubjectCharCount(selectedAnnouncement.subject.length);
      setBodyCharCount(selectedAnnouncement.body.length);
    }
  }, [selectedAnnouncement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'subject') {
      if (value.length <= 20) {
        setAnnouncement({ ...announcement, [name]: value });
        setSubjectCharCount(value.length);
        setSubjectError('');
      } else {
        setSubjectError('Too many characters. Please keep the text up to 20 characters long.');
      }
    } else if (name === 'body') {
      if (value.length <= 120) {
        setAnnouncement({ ...announcement, [name]: value });
        setBodyCharCount(value.length);
        setBodyError('');
      } else {
        setBodyError('Too many characters. Please keep the text up to 120 characters long.');
      }
    }
  };

  const handleItemSelect = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const handleSelectItem = (type) => {
    if (sendTo !== type) {
      setSelectedItems([]);
      setDisplayItems([]);
    }
    setSendTo(type);
    setItemPicker(true);
  };

  const handleDateChange = (date, field) => {
    const now = new Date();
    if (field === 'startTime') {
      if (date < now) {
        setTimeError('Start Time cannot be in the past');
        return;
      }
      const oneYearLater = new Date(date.getTime() + 365 * 24 * 60 * 60 * 1000);
      setAnnouncement((prev) => ({
        ...prev,
        startTime: date,
        endTime: prev.endTime && date >= prev.endTime ? new Date(date.getTime() + 24 * 60 * 60 * 1000) :
          (prev.endTime > oneYearLater ? oneYearLater : prev.endTime),
      }));
      setTimeError('');
    } else if (field === 'endTime') {
      const oneYearLater = new Date(announcement.startTime.getTime() + 365 * 24 * 60 * 60 * 1000);
      if (date <= announcement.startTime) {
        setTimeError('End Time must be after Start Time');
      } else if (date > oneYearLater) {
        setTimeError('End Time cannot be more than one year after Start Time');
      } else {
        setAnnouncement((prev) => ({ ...prev, endTime: date }));
        setTimeError('');
      }
    }
  };

  // Function to get the end of the day
  const getEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  };

  const handlePostAnnouncement = async () => {

    if (!sendTo) {
      setError('Please select targets');
      return;
    }

    // if (!announcement.subject.trim()) {
    //   setError('Subject is required');
    //   return;
    // }

    if (!announcement.body.trim()) {
      setError('Body is required');
      return;
    }

    setError('');

    const apiUrl = selectedAnnouncement
      ? `${process.env.REACT_APP_ANNOUNCEMENT_API_URL}/update_announcement`
      : `${process.env.REACT_APP_ANNOUNCEMENT_API_URL}/create_announcement`;

    const requestBody = {
      id: announcement.id,
      organizationId: announcement.organizationId,
      subject: announcement.subject,
      body: announcement.body,
      sentTo: sendTo,
      startTime: announcement.startTime.toISOString(),
      endTime: announcement.endTime.toISOString(),
      policyGroupIds: sendTo === "policy_group" ? selectedItems : [],
      devices: sendTo === "device" ? selectedItems : [],
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        body: JSON.stringify(requestBody),
        credentials: "include",
      });

      if (response.ok) {
        console.log('Announcement successfully created/updated');
        onSend();
      } else {
        console.log("Failed to post/update announcement");
        console.log('Response status:', response.status);
        console.log('Response text:', await response.text());
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (item.serialNo && item.serialNo.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(filteredItems.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleCancel = () => {
    setItemPicker(false);
    setSearchQuery("");
    setSelectAll(false);
  };

  const handleConfirm = () => {
    setItemPicker(false);
    setAnnouncement(prev => ({
      ...prev,
      policyGroupIds: sendTo === "policy_group" ? selectedItems : [],
      devices: sendTo === "device" ? selectedItems : [],
    }));
    // Update displayItems based on the selected items
    if (sendTo === "device") {
      const selectedDevices = items.filter(item => selectedItems.includes(item.id));
      setDisplayItems(selectedDevices.map(device => device.name));
    } else if (sendTo === "policy_group") {
      const selectedGroups = groups.filter(group => selectedItems.includes(group.id));
      setDisplayItems(selectedGroups.map(group => group.name));
    }
  };

  function parseISOString(isoString) {
    const parts = isoString.match(/\d+/g);
    return new Date(Date.UTC(
      parseInt(parts[0], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[2], 10),
      parseInt(parts[3], 10),
      parseInt(parts[4], 10),
      parseInt(parts[5], 10)
    ));
  }

  const displaySelectedItems = displayItems.length > 0
    ? displayItems.slice(0, 5).join(', ') + (displayItems.length > 5 ? '...' : '')
    : null;

  return (
    <div className="white_shd full margin_bottom_30" style={{ padding: "40px" }}>
      <ArrowBackRoundedIcon
        onClick={onBackClick}
        sx={{
          fontSize: "2rem",
          color: "black",
          cursor: "pointer",
          marginLeft: "0",
          marginTop: "0",
          marginBottom: "30px",
        }}
      />
      <div style={{ display: "flex", alignItems: "center", marginBottom: "50px" }}>
        <h2>{selectedAnnouncement ? "Edit Announcement" : "New Announcement"}</h2>
      </div>

      <div className="col-md-6" style={{ padding: 0 }}>
        <form className="contact-form">
          {/* <div className="form-group" style={{ marginBottom: 20 }}>
            <label htmlFor="subject" style={{ fontSize: 16, fontWeight: 400, color: "black" }}>Subject</label>
            <input
              className="form-control"
              type="text"
              id="subject"
              name="subject"
              value={announcement.subject}
              onChange={handleInputChange}

              placeholder="E.g. Cafe Opening Times"

            />
            <small>{`${subjectCharCount}/20 characters`}</small>
            {subjectError && <div className="error-message" style={{ color: 'red' }}>{subjectError}</div>}
          </div> */}
          <div className="form-group" style={{ marginBottom: 20 }}>
            <label htmlFor="body" style={{ fontWeight: 400, fontSize: 16, color: "black" }}>Body</label>
            <textarea
              className="form-control"
              id="body"
              name="body"
              style={{
                height: 150,
                overflowY: "scroll",
                resize: "none",
                scrollbarColor: "#007bff",
              }}
              value={announcement.body}
              onChange={handleInputChange}
              placeholder="Enter the body of the announcement"


            />
            <small>{`${bodyCharCount}/120 characters`}</small>
            {bodyError && <div className="error-message" style={{ color: 'red' }}>{bodyError}</div>}
          </div>
          <div className="form-group" style={{ marginBottom: 20 }}>
            <label style={{ fontWeight: 400, fontSize: 16, color: "black" }}>
              Send to
              {selectedAnnouncement ? (
                <div>{displayTarget}</div>
              ) : (
                <div>
                  <label htmlFor="all_policy_group" style={{ marginRight: 70 }}>
                    <input
                      type="radio"
                      name="sendTo"
                      id="all_policy_group"
                      style={{ marginRight: 5, accentColor: "#007bff" }}
                      value="all_policy_group"
                      checked={sendTo === "all_policy_group"}
                      onChange={() => {
                        setSendTo("all_policy_group");
                        setSelectedItems([]);
                        setDisplayItems([]);
                      }}
                    />
                    Every group
                  </label>
                  <label htmlFor="policy_group" style={{ marginRight: 70 }}>
                    <input
                      type="radio"
                      name="sendTo"
                      id="policy_group"
                      style={{ marginRight: 5, accentColor: "#007bff" }}
                      value="policy_group"
                      checked={sendTo === "policy_group"}
                      onChange={() => handleSelectItem("policy_group")}
                    />
                    Select Policy Groups
                  </label>
                  <label htmlFor="device">
                    <input
                      type="radio"
                      name="sendTo"
                      id="device"
                      style={{ marginRight: 5, accentColor: "#007bff" }}
                      value="device"
                      checked={sendTo === "device"}
                      onChange={() => handleSelectItem("device")}
                    />
                    Select Devices
                  </label>
                </div>
              )}
            </label>
          </div>

          {(sendTo === "policy_group" || sendTo === "device") && (
            <div className="form-group" style={{ marginBottom: 20 }}>
              <label style={{ fontWeight: 400, fontSize: 16, color: "black" }}>
                Selected {sendTo === "policy_group" ? "groups" : "devices"}:
              </label>
              <div>{displaySelectedItems}</div>
              <button
                type="button"
                className="btn cur-p btn-dark"
                onClick={() => setItemPicker(true)}
                style={{ marginTop: 10, width: "135px" }}
              >
                Modify Selection
              </button>
            </div>
          )}

          <div className="form-group" style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 10 }}>
              <label htmlFor="schedule-start-date" style={{ fontWeight: 400, fontSize: 16, color: "black", marginRight: 10 }}>Start Time:</label>
              <DatePicker
                selected={announcement.startTime}
                onChange={(date) => handleDateChange(date, "startTime")}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                className="form-control"
                style={{ width: "200px", }}
                minDate={announcement.startTime}
              />
            </div>
            <div>
              <label htmlFor="schedule-end-date" style={{ fontWeight: 400, fontSize: 16, color: "black", marginRight: 18 }}>End Time:</label>
              <DatePicker
                selected={announcement.endTime}
                onChange={(date) => handleDateChange(date, "endTime")}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                className="form-control"
                style={{ width: "200px", marginLeft: "8px" }}
                minDate={announcement.startTime}
                maxDate={new Date(announcement.startTime.getTime() + 365 * 24 * 60 * 60 * 1000)}
                minTime={new Date(announcement.startTime.getTime())}
                maxTime={getEndOfDay(new Date(announcement.startTime.getTime() + 365 * 24 * 60 * 60 * 1000))}
              />
            </div>
            {timeError && (

              <p style={{ color: 'red' }}>{timeError}</p>

            )}

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>

          <button
            type="button"
            className="btn cur-p btn-primary"
            onClick={handlePostAnnouncement}
            disabled={(sendTo === "policy_group" || sendTo === "device") && selectedItems.length === 0}
          >
            {selectedAnnouncement ? "Update Announcement" : "Post Announcement"}
          </button>
        </form>
      </div>

      {itemPicker && (
        <div className="modal" tabIndex={"-1"} role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ height: "98vh", minWidth: "700px" }}>
              <div className="modal-header">
                <h1 className="modal-title">Select {sendTo === "policy_group" ? "Groups" : "Devices"}</h1>
                <button className="close-button" onClick={handleCancel}>&times;</button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Search ${sendTo === "policy_group" ? "groups" : "devices"}...`}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                <div style={{ margin: "10px 0" }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      style={{ marginRight: "5px" }}
                    />
                    Select All
                  </label>
                </div>

                <table className="table table-hover table-wrap" style={{ margin: "20px 0" }}>
                  <thead>
                    <tr>
                      <th>{sendTo === "policy_group" ? "Group Name" : "Device Name"}</th>
                      {sendTo === "device" && <th>Device S/N</th>}
                      <th style={{ width: "50px" }}>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        {sendTo === "device" && <td>{item.serialNo}</td>}
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleItemSelect(item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleConfirm}
                  disabled={selectedItems.length === 0}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


function EventCalendar({ resetFlag }) {
  return (
    <div className="midde_cont">
      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title">
              <h2>Event Calendar</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ManageUsers({ userInfo, selectedOrg, orgGuid, resetFlag }) {
  const [users, setUsers] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [newUser, setNewUser] = useState({ name: "", email: "", role: "" });
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  //const isManager = userInfo?.role === "manager";
  const isStaff = userInfo?.role === "staff";
  const [emailError, setEmailError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers(orgGuid);
  }, [userInfo, orgGuid]);

  useEffect(() => {
    // 重置组件状态
    setShowInviteModal(false);
    setNewUser({ name: "", email: "", role: "" });
    setDeleteUserId(null);
    setShowEditUserModal(false);
    setEditedUser(null);
    setShowDeleteModal(false);
  }, [resetFlag]);

  const fetchUsers = async (orgGuid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${orgGuid}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const usersWithStatus = data.map((user) => {
          const passwordHash = user.passwordHash || "";
          const status = passwordHash ? "active" : "pending";
          return { ...user, status };
        });
        setUsers(usersWithStatus);
      } else {
        console.log("Failed to fetch users.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleInviteClick = () => {
    setShowInviteModal(true);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    if (name === "email") {
      setEmailTouched(true);
      if (!validateEmail(value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(newUser.email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/invite-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({
            organizationGuid: selectedOrg.id,
            name: newUser.name,
            email: newUser.email,
            role: newUser.role,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newUserWithStatus = {
          ...newUser,
          id: Date.now(),
          status: "pending",
        };
        setUsers([...users, newUserWithStatus]);
        setNewUser({ name: "", email: "", role: "" });
        setShowInviteModal(false);
        toast.success("User invited!");
        fetchUsers(orgGuid);
      } else {
        toast.error("Failed to invite user. Please try again.");
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleInviteCancel = () => {
    setShowInviteModal(false);
    setNewUser({ name: "", email: "", role: "" });
    setEmailError("");
    setEmailTouched(false);
  };

  const handleDeleteUserClick = (userId) => {
    if (userId) {
      setDeleteUserId(userId);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteUserConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${deleteUserId}`,
        {
          method: "DELETE",

          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedUsers = users.filter((user) => user.id !== deleteUserId);
        setUsers(updatedUsers);
        setDeleteUserId(null);

        toast.success("User deleted!");
        setShowDeleteModal(false);
        fetchUsers(orgGuid);

        if (deleteUserId === userInfo.userId) {
          // Perform logout
          await handleLogout();
        }
      } else {
        toast.error("Failed to delete user. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        credentials: "include",
      });

      if (response.ok) {
        // Clear local storage
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("userInfo");

        // Redirect to login page
        navigate("/");
        toast.success(
          "Your account has been deleted. You have been logged out."
        );
      } else {
        console.log("Logout failed.");
        toast.error(
          "Failed to log out. Please close your browser to complete the process."
        );
      }
    } catch (error) {
      console.error("Error logging out:", error);
      toast.error(
        "An error occurred during logout. Please close your browser to complete the process."
      );
    }
  };
  const handleDeleteUserCancel = () => {
    setShowDeleteModal(false);
  };

  const handleUserClick = (user) => {
    if (isStaff) return;
    setEditedUser(user);
    setShowEditUserModal(true);
  };

  const handleEditUserInputChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleEditUserSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${editedUser.userId}`,
        {
          method: "PUT",

          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({
            name: editedUser.name,
            organizationGuid: selectedOrg.id,
            role: editedUser.role,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === editedUser.id ? editedUser : user
        );
        setUsers(updatedUsers);
        setShowEditUserModal(false);
        toast.success("User updated!");
        fetchUsers(orgGuid);
      } else {
        console.log("Failed to update user.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleEditUserCancel = () => {
    setShowEditUserModal(false);
  };

  return (
    <div className="midde_cont">
      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="row column_title">
          <div className="col-md-12">
            <div
              className="page_title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h2 style={{ flex: "1" }}>Manage Users</h2>
            </div>

            <div className="actions col-md-12">
              {isStaff ? null : (
                <button
                  className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                  onClick={handleInviteClick}
                >
                  <b>Invite +</b>
                </button>
              )}
            </div>
            <table className="table table-hover">
              <thead>
                <tr key="headers">
                  <th key="Name">Name</th>
                  <th key="Email">Email</th>
                  <th key="Role">Role</th>
                  <th key="Status">Status</th>
                  {isStaff ? null : <th key="Action"></th>}
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} onClick={() => handleUserClick(user)}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td className="status">
                      {user.passwordHash ? (
                        <span className="active">{user.status}</span>
                      ) : (
                        <span className="waiting">{user.status}</span>
                      )}
                    </td>
                    {isStaff ? null : (
                      <td>
                        <TrashIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteUserClick(user.userId);
                          }}
                          sx={{
                            fontSize: "1rem", // 调整图标大小
                            color: "black", // 调整图标颜色
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showInviteModal && (
          <div className="modal" tabIndex={"-1"} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "400px" }}>
                <div className="modal-header">
                  <h1 className="modal-title">Invite User</h1>
                  <button className="close-button" onClick={handleInviteCancel}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <form className="contact-form">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={newUser.name}
                        onChange={handleUserInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className={`form-control ${emailError ? "is-invalid" : ""
                          }`}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="example@xxx.com"
                        value={newUser.email}
                        onChange={handleUserInputChange}
                        required
                      />
                      {emailError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {emailError}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        className="form-control"
                        id="role"
                        name="role"
                        value={newUser.role}
                        onChange={handleUserInputChange}
                        required
                      >
                        <option value="">Select a role</option>
                        <option value="Manager">Manager</option>
                        <option value="Staff">Staff</option>
                      </select>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn cur-p btn-secondary"
                        onClick={handleInviteCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn cur-p btn-primary"
                        onClick={handleInviteSubmit}
                        disabled={
                          !newUser.name ||
                          !newUser.email ||
                          !newUser.role ||
                          !!emailError
                        }
                      >
                        Invite
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title">Delete User</h1>
                  <button
                    type="button"
                    className="close-button"
                    onClick={handleDeleteUserCancel}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete{" "}
                    <strong>
                      {users.find((user) => user.userId === deleteUserId)?.name}
                    </strong>
                    ?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDeleteUserCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteUserConfirm}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showEditUserModal && (
          <div className="modal" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "400px" }}>
                <div className="modal-header">
                  <h1 className=" modal-title">Edit User</h1>
                  <button
                    className="close-button"
                    onClick={handleEditUserCancel}
                  >
                    &times;
                  </button>
                </div>
                <form className="contact-form">
                  <div className="form-group" style={{ position: "relative" }}>
                    <div>
                      <label htmlFor="staticEmail" className=" col-form-label">
                        Email:
                      </label>
                      <input
                        readOnly
                        className="form-control-plaintext"
                        id="staticEmail"
                        type="text"
                        name="email"
                        value={editedUser.email}
                        style={{
                          paddingLeft: "10px",
                          backgroundColor: "#e9ecef",
                        }}
                      />
                    </div>
                    <span>
                      <FontAwesomeIcon
                        icon={faBan}
                        style={{
                          position: "absolute",
                          top: "75%",
                          left: "93%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </span>
                  </div>

                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={editedUser.name}
                      onChange={handleEditUserInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="custom-select"
                      name="role"
                      value={editedUser.role}
                      onChange={handleEditUserInputChange}
                    >
                      <option value="Manager">Manager</option>
                      <option value="Staff">Staff</option>
                    </select>
                  </div>

                  <div className="modal-footer">
                    <input
                      type="submit"
                      className="btn cur-p btn-secondary"
                      value="Cancel"
                      onClick={handleEditUserCancel}
                    />
                    <input
                      type="submit"
                      className="btn cur-p btn-primary"
                      value="Save"
                      onClick={handleEditUserSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Userboard;
